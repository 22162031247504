import { Alert, Link } from '@mui/material';
import React from 'react';

export const InfoBox = (): JSX.Element => {
  return (
    <>
      <Alert severity="info">
        Price Calculator is meant to predict offer/ ad price! <br></br>
        Please send any feedback to the{' '}
        <Link href="https://e-farmgmbh.slack.com/archives/C01NZ07BLCV">
          #ds-team
        </Link>{' '}
        channel in Slack with a copy of the relevant URL and a description of
        what the desired response would be. Brand, model or other feature
        requests can also be shared in this channel.
      </Alert>
    </>
  );
};
