import React from 'react';

import { Link } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Machine } from 'app/viewAreas/machines/machineselector/Machine';
import { formatPercentage } from 'utils/format';
import { formatDate } from 'utils/format/formatDate';
import { formatPrice } from 'utils/format/formatPrice';

export const gridDefinition: GridColDef<Machine>[] = [
  {
    field: 'machineGroupId',
    headerName: 'ID',
    width: 100,
    type: 'string',
    sortable: true,
    valueGetter: (params) => params.row.machineGroupId,
  },
  {
    field: '',
    headerName: 'Links',
    width: 100,
    type: 'string',
    sortable: false,
    valueGetter: () => '',
    renderCell: (params) => (
      <span style={{ whiteSpace: 'break-spaces' }}>
        <Link
          href={`https://e-farm.com/${params.row.machineGroupId}`}
          target="_blank"
        >
          Web
        </Link>{' '}
        <Link href={params.row.zohoLink} target="_blank">
          Zoho
        </Link>
      </span>
    ),
  },
  {
    field: 'brand',
    headerName: 'Brand',
    width: 120,
    type: 'string',
    sortable: true,
    valueGetter: (params) => params.row.brand,
  },
  {
    field: 'model',
    headerName: 'Model',
    width: 120,
    type: 'string',
    sortable: true,
    valueGetter: (params) => params.row.model,
  },
  {
    field: 'country',
    headerName: 'Country',
    type: 'string',
    sortable: true,
    valueGetter: (params) => params.row.country,
  },
  {
    field: 'yearOrProduction',
    headerName: 'Year',
    width: 60,
    type: 'string',
    sortable: true,
    valueGetter: (params) => params.row.yearOrProduction,
  },
  {
    field: 'engineHours',
    headerName: 'Hours',
    width: 80,
    type: 'number',
    sortable: true,
    valueGetter: (params) => params.row.engineHours,
  },
  {
    field: 'unitPrice',
    headerName: 'Offer Price',
    width: 120,
    align: 'right',
    type: 'number',
    sortable: true,
    valueGetter: (params) => formatPrice(params.row.unitPrice),
  },
  {
    field: 'exactValuation',
    headerName: 'PICO Estimate',
    width: 130,
    align: 'right',
    type: 'number',
    sortable: true,
    valueGetter: (params) => formatPrice(params.row.exactValuation),
  },
  {
    field: 'priceScore2',
    headerName: 'Price Diff',
    width: 100,
    align: 'center',
    type: 'number',
    sortable: true,
    valueGetter: (params) => params.row.priceScore2,
    valueFormatter: (params) => formatPercentage(params.value, 1),
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    width: 120,
    type: 'string',
    sortable: true,
    valueGetter: (params) => params.row.createdAt,
    valueFormatter: (params) => formatDate(params.value),
  },
  {
    field: 'daysOnline',
    headerName: 'Days Online',
    width: 90,
    align: 'center',
    type: 'number',
    sortable: true,
    valueGetter: (params) => params.row.daysOnline,
  },
  {
    field: 'priceScore',
    headerName: 'Price Score',
    width: 100,
    align: 'center',
    type: 'number',
    sortable: true,
    valueGetter: (params) => params.row.priceScore,
    valueFormatter: (params) => params.value.toFixed(1),
  },
  {
    field: 'locationScore',
    headerName: 'Location Score',
    width: 110,
    align: 'center',
    type: 'number',
    sortable: true,
    valueGetter: (params) => params.row.locationScore,
  },
  {
    field: 'recencyScore',
    headerName: 'Recency Score',
    width: 110,
    align: 'center',
    type: 'number',
    sortable: true,
    valueGetter: (params) => params.row.recencyScore,
    valueFormatter: (params) => params.value.toFixed(1),
  },
  {
    field: 'magicScore',
    headerName: 'Magic Score',
    width: 100,
    align: 'center',
    type: 'number',
    sortable: true,
    valueGetter: (params) => params.row.magicScore,
    valueFormatter: (params) => params.value.toFixed(1),
  },
  {
    field: 'dealerName',
    headerName: 'Dealer Name',
    width: 200,
    type: 'string',
    sortable: true,
    valueGetter: (params) =>
      params.row.dealerName != null ? params.row.dealerName : 'Farmer',
  },
];
