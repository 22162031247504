import { AttachMoney } from '@mui/icons-material';
import { TViewArea } from 'app/viewAreas/TViewArea';
import { Escobar } from './escobar/Loadable';

export const viewArea: TViewArea = {
  route: '/dealers',
  label: 'Dealers',
  icon: AttachMoney,
  requiredRoles: ['EFarmAdmin'],
  children: [
    {
      route: '/escobar',
      label: 'Escobar',
      component: Escobar,
    },
    // [INSERT NEW VIEW BELOW] < Needed for generating views seamlessly
  ],
};
