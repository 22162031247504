import React from 'react';

import { ChevronRight } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { SearchQueryParams } from 'app/viewAreas/machines/quickcalculator/types';
import { formatPrice } from 'utils/format/formatPrice';

type Props = {
  calculations: {
    searchQueryParams: SearchQueryParams;
    price: number;
    ts: number;
  }[];
  setSearchQueryParams: (qp: Partial<SearchQueryParams>) => void;
  setPrice: React.Dispatch<React.SetStateAction<number | null>>;
};

export const CalculationsTable = ({
  calculations,
  setSearchQueryParams,
  setPrice,
}: Props): JSX.Element => {
  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        Recent calculations
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Brand</TableCell>
              <TableCell align="center">Model</TableCell>
              <TableCell align="center">Country</TableCell>
              <TableCell align="center">Year</TableCell>
              <TableCell align="center">Hour Range</TableCell>
              <TableCell align="center">Front Linkage</TableCell>
              <TableCell align="center">Front PTO</TableCell>
              <TableCell align="center">Front Loader</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calculations.map((calculation) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={calculation.ts}
              >
                <TableCell component="th" scope="row" align="center">
                  {calculation.searchQueryParams.brand}
                </TableCell>
                <TableCell align="center">
                  {calculation.searchQueryParams.model}
                </TableCell>
                <TableCell align="center">
                  {calculation.searchQueryParams.country}
                </TableCell>
                <TableCell align="center">
                  {calculation.searchQueryParams.year}
                </TableCell>
                <TableCell align="center">
                  {calculation.searchQueryParams.hourRange}
                </TableCell>
                <TableCell align="center">
                  {calculation.searchQueryParams.linkageFront ? 'X' : ''}
                </TableCell>
                <TableCell align="center">
                  {calculation.searchQueryParams.ptoFront ? 'X' : ''}
                </TableCell>
                <TableCell align="center">
                  {calculation.searchQueryParams.frontLoader ? 'X' : ''}
                </TableCell>
                <TableCell align="right">
                  {formatPrice(calculation.price)}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => {
                      setSearchQueryParams(calculation.searchQueryParams);
                      setPrice(null);
                    }}
                    color="primary"
                    component="label"
                  >
                    <ChevronRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
