import { picoClient } from 'app/viewAreas/machines/quickcalculator/picoClient';
import {
  ContextPrice,
  GetContextPricesResponseData,
  GetPriceResponseData,
} from 'app/viewAreas/machines/quickcalculator/types';
import countryList from './constants/country_isocode.json';
import { convertHourRangesToHours } from './helpers';

export const getPrice = async (search: {
  brand: string | undefined;
  model: string | undefined;
  country: string | undefined;
  year: number | undefined;
  hourRange: string | undefined;
  linkageFront: boolean;
  ptoFront: boolean;
  frontLoader: boolean;
}) => {
  if (search.country && !countryList[search.country]) {
    throw new Error(`Country ${search.country} is not in the country list`);
  }

  const { data } = await picoClient.post<GetPriceResponseData>(
    `/api/v3/price`,
    {
      BRAND: search.brand,
      MODEL: search.model,
      YEAR_OF_PRODUCTION: search.year,
      LOCATION_COUNTRY: search.country,
      // TODO: Should we raise an exception if the hour range is not valid?
      ENGINE_HOURS: convertHourRangesToHours(search.hourRange),
      LINKAGE_FRONT: search.linkageFront,
      PTO_FRONT: search.ptoFront,
      FRONT_LOADER: search.frontLoader,
    },
  );

  return {
    price: data.price,
    lowerBoundary: data.lower_bound,
    upperBoundary: data.upper_bound,
    relCiWidth: data.rel_ci_width,
    trustLevel: data.trust_level,
  };
};

export const getContextPrices = async (searchData: {
  brand: string;
  model: string;
  year: number;
}): Promise<ContextPrice[]> => {
  const { data } = await picoClient.get<GetContextPricesResponseData>(
    `/api/v3/context_prices`,
    {
      params: {
        brand_name: searchData.brand,
        model_name: searchData.model,
        production_year: searchData.year,
      },
    },
  );

  return data.map((item) => ({
    brandName: item.Brand_Name,
    hours: item.Hours,
    mappedModel: item.Mapped_Model,
    unitPrice: item.Unit_Price,
    year: item.Year,
    createdTime: item.Created_Time,
  }));
};
