import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Grid } from '@mui/material';
import { Layout } from 'app/components/Layout/Loadable';
import { UnauthorizedPage } from 'app/components/Layout/UnauthorizedPage/Loadable';
import { useRoles } from 'app/hooks';
import React, { useEffect } from 'react';

const returnTo = (): string =>
  `${window.location.pathname}${window.location.search}`;

type Props = {
  requiredRoles: string[];
  component: React.ComponentType;
};

export const AuthenticationRequired: React.FC<Props> = ({
  requiredRoles,
  component,
}) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { hasRoles, getMissingRoles } = useRoles();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    const doLoginWithRedirect = async () =>
      await loginWithRedirect({
        appState: {
          returnTo: returnTo(),
        },
      });

    doLoginWithRedirect();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isAuthenticated && hasRoles(requiredRoles)) {
    const Component = component as React.ComponentType;
    return <Component />;
  }

  if (isAuthenticated && !hasRoles(requiredRoles)) {
    return (
      <Layout title="">
        <UnauthorizedPage missingRoles={getMissingRoles(requiredRoles)} />
      </Layout>
    );
  }

  // is not authenticated
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
