import { AuthenticationRequired } from 'app/AuthenticationRequired';
import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';

type Props = RouteProps & {
  requiredRoles?: string[];
};

export const ProtectedRoute: FC<Props> = React.memo(
  ({ component, requiredRoles = [], ...args }) => {
    if (!component) {
      return null;
    }

    return (
      <Route {...args}>
        <AuthenticationRequired
          requiredRoles={requiredRoles}
          component={component as React.ComponentType}
        />
      </Route>
    );
  },
);
