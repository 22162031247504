import { Agriculture } from '@mui/icons-material';
import { TViewArea } from 'app/viewAreas/TViewArea';
import { General } from './general/Loadable';
import { MachineSelector } from './machineselector';
import { PDFForm } from './pdf';
import { QuickCalculator } from './quickcalculator';

export const viewArea: TViewArea = {
  route: '/machines',
  label: 'Machines',
  icon: Agriculture,
  children: [
    {
      route: '/general',
      label: 'General',
      component: General,
    },
    {
      route: '/pdf',
      label: 'PDF',
      component: PDFForm,
    },
    {
      route: '/quick-calculator',
      label: 'Price Calculator',
      component: QuickCalculator,
    },
    {
      route: '/machineselector',
      label: 'Machine Selector',
      component: MachineSelector,
    },
    // [INSERT NEW VIEW BELOW] < Needed for generating views seamlessly
  ],
};
