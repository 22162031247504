import * as MMD from '@it-efarm/model-metadata';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckboxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import PrevIcon from '@mui/icons-material/NavigateBefore';
import NextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';
import React from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import dayjs from 'dayjs';
import type { KEYS } from '@it-efarm/zeus';
import { DefaultProps } from 'utils/react';
import { areEqual, FixedSizeList } from 'react-window';
import { CircularProgress, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Brand, ExpansionState, MachineModel } from './domain';
import {
  selectFilteredBrands,
  selectDiff,
  selectExpandedBrandsSummary,
  selectIsBrandExpanded,
  selectIsFetching,
} from './selectors';
import { actions, FilterContext } from './state';
import styles from './MachineModelsTables.module.scss';
import { mergeDeep } from 'utils/object';

type Column = {
  label: React.ReactNode;
  render: (model: MachineModel) => string;
  isColumnEditable?: false;
};

type EditableColumn = {
  label: React.ReactNode;
  render: (model: MachineModel) => string;
  type: React.HTMLInputTypeAttribute;
  parse: (s: string) => MMD.domain.Value<unknown>;
  key: string;
  isColumnEditable: true;
};

const columns: (Column | EditableColumn)[] = [
  { label: 'Model', render: (v) => v.model },
  { label: 'Machine Type', render: (v) => v.machineType },
  { label: 'Sub Type', render: (v) => v.subType || '' },
  {
    label: 'Year',
    render: (v) => {
      if (v.yearOfProductionStart === undefined) {
        return '';
      }

      const start = v.yearOfProductionStart.toString();
      const end = v.yearOfProductionEnd?.toString();

      return end !== undefined ? `${start} - ${end}` : start;
    },
  },
  {
    label: 'Updated At',
    render: (v) => dayjs(v.updatedAt).format('YYYY/MM/DD'),
  },
  {
    key: 'ENGINE_HP' as KEYS.ENGINE_HP,
    label: <div className="text-right">Engine Power (hp)</div>,
    isColumnEditable: true,
    render: (v) => v.data.ENGINE_HP?.value.toString() || '',
    parse: (s) =>
      MMD.domain.Value.createStandardEquipment(Number.parseInt(s, 10)),
    type: 'number',
  },
];

const EditableCell: React.FC<
  DefaultProps<{
    column: EditableColumn;
    hasChanges: boolean;
    isLast: boolean;
    machineModel: MachineModel;
  }>
> = React.memo(({ column, hasChanges, isLast, machineModel }) => {
  const dispatch = useDispatch();
  const updateMachineModelData = React.useCallback(
    ({ target: { value } }) => {
      dispatch(
        actions.updateMachineModelData({
          id: machineModel.id,
          value: column.parse(value),
          key: column.key,
        }),
      );
    },
    [dispatch, column, machineModel.id],
  );

  return (
    <input
      className={
        'h-full w-full flex items-center pl-2 border border-transparent border-b-neutral-300 last:pr-2 focus:outline-none focus:border-sky-500' +
        (hasChanges ? ' bg-yellow-200 border-x-neutral-300' : '') +
        (isLast ? ' border-b-0' : '')
      }
      type={column.type}
      min={0}
      value={column.render(machineModel)}
      onChange={updateMachineModelData}
    ></input>
  );
});

const Cell: React.FC<
  DefaultProps<{
    column: Column;
    hasChanges: boolean;
    isLast: boolean;
    machineModel: MachineModel;
  }>
> = React.memo(({ column, isLast, machineModel }) => {
  return (
    <div
      className={
        'h-full w-full flex items-center pl-2 border border-transparent border-b-neutral-300 last:pr-2 focus:outline-none focus:border-sky-500' +
        (isLast ? ' border-b-0' : '')
      }
    >
      {column.render(machineModel)}
    </div>
  );
});

const useMergeMachineModelWithDiff = (
  raw: MachineModel,
): { machineModel: MachineModel; hasChanges: boolean } => {
  const diff = useSelector((state) => selectDiff(state, raw.id));
  return React.useMemo(() => {
    if (diff === undefined) {
      return { machineModel: raw, hasChanges: false };
    }
    return { machineModel: mergeDeep(raw, { data: diff }), hasChanges: true };
  }, [raw, diff]);
};

const MachineModelRow: React.FC<
  DefaultProps<{
    index: number;
    style: React.CSSProperties;
    data: MachineModel[];
  }>
> = React.memo(({ index, style, data }) => {
  const raw = data[index];
  const isLast = index + 1 === data.length;
  const rowClassName = `col-span-full grid items-center ${styles.gridTemplateColumnsDataRow} `;
  const { machineModel, hasChanges } = useMergeMachineModelWithDiff(raw);

  const dispatch = useDispatch();
  const resetChanges = React.useCallback(
    () => dispatch(actions.resetChanges(machineModel.id)),
    [dispatch, machineModel.id],
  );
  const persistChanges = React.useCallback(
    () => dispatch(actions.persistChangesTrigger(machineModel)),
    [dispatch, machineModel],
  );

  return (
    <div style={style} className={rowClassName}>
      <div className="h-full w-full flex items-center pl-2 border-b border-neutral-300">
        {hasChanges && (
          <>
            <IconButton
              size="small"
              className="border-orange-500 hover:border-orange-600 text-orange-500"
              onClick={resetChanges}
            >
              <RestartAltIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              className="border-sky-500 hover:border-sky-600 text-sky-500"
              onClick={persistChanges}
            >
              <SaveIcon fontSize="small" />
            </IconButton>
          </>
        )}
      </div>
      {columns.map((column) =>
        column.isColumnEditable ? (
          <EditableCell
            key={`row-cell-${column.label}-${machineModel.id}`}
            column={column}
            hasChanges={hasChanges}
            isLast={isLast}
            machineModel={machineModel}
          />
        ) : (
          <Cell
            key={`row-cell-${column.label}-${machineModel.id}`}
            column={column}
            hasChanges={hasChanges}
            isLast={isLast}
            machineModel={machineModel}
          />
        ),
      )}
    </div>
  );
}, areEqual);

const BrandRow: React.FC<
  DefaultProps<{
    brand: Brand;
    machineModels: MachineModel[];
  }>
> = React.memo(({ brand, machineModels }) => {
  const isExpanded = useSelector((state) =>
    selectIsBrandExpanded(state, brand),
  );
  const dispatch = useDispatch();
  const toggleBrand = React.useCallback(
    () => dispatch(actions.toggleBrand(brand)),
    [dispatch, brand],
  );

  const className = `col-span-full grid items-center border-x border-t ${
    isExpanded ? 'border-b' : 'last-of-type:border-b'
  } border-neutral-300 ${styles.gridTemplateColumnsTable}`;

  const ROOT_FONT_SIZE = 16;
  const BORDER_HEIGHT = 1;
  const ROW_HEIGHT = ROOT_FONT_SIZE * 2 + BORDER_HEIGHT;
  const MAX_VISIBLE_ROWS = 7;

  return (
    <>
      <div className={className}>
        <div className="flex justify-center">
          <IconButton size="small" onClick={toggleBrand}>
            {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </IconButton>
        </div>
        <div>{brand}</div>
        <div>{machineModels.length}</div>
      </div>
      {isExpanded && (
        <div className="col-start-2 col-span-full border-x last:border-b border-neutral-300 ml-8">
          <header
            className={`col-span-full grid items-center py-1 bg-neutral-50 border-b border-neutral-300 ${styles.gridTemplateColumnsDataRow}`}
          >
            <div className="pl-2 last:pr-2 py-1"></div>
            {columns.map(({ label }) => (
              <div className="pl-2 last:pr-2 py-1" key={`row-header-${label}`}>
                {label}
              </div>
            ))}
          </header>
          <FixedSizeList
            // `react-window` works by absolutely positioning values,
            // hence we need to do some manual calculations
            height={
              machineModels.length > MAX_VISIBLE_ROWS
                ? ROW_HEIGHT * MAX_VISIBLE_ROWS + ROW_HEIGHT / 2
                : ROW_HEIGHT * machineModels.length
            }
            itemCount={machineModels.length}
            itemSize={ROW_HEIGHT}
            width="100%"
            itemData={machineModels}
          >
            {MachineModelRow}
          </FixedSizeList>
        </div>
      )}
    </>
  );
});

const TableHeader: React.FC<DefaultProps> = () => {
  const expandedBrandsSummary = useSelector(selectExpandedBrandsSummary);
  const areAllExpanded = expandedBrandsSummary === ExpansionState.ALL;
  const areSomeExpanded = expandedBrandsSummary === ExpansionState.SOME;
  const dispatch = useDispatch();
  const brands = useSelector(selectFilteredBrands);
  const toggleAllBrands = React.useCallback(
    () => dispatch(actions.toggleAllBrands()),
    [dispatch],
  );

  return (
    <header
      className={`col-span-full grid items-center py-1 mt-2 bg-neutral-50 border border-neutral-300 ${styles.gridTemplateColumnsTable}`}
    >
      <div className="flex justify-center">
        <IconButton size="small" onClick={toggleAllBrands}>
          {areAllExpanded ? (
            <CheckboxIcon />
          ) : areSomeExpanded ? (
            <IndeterminateCheckBoxIcon />
          ) : (
            <CheckBoxOutlineBlankIcon />
          )}
        </IconButton>
      </div>
      <div>Brand ({brands.length})</div>
      <div>Total Models</div>
    </header>
  );
};

const TableBody: React.FC<DefaultProps> = () => {
  const { paginatedBrands, filteredMachineModelByBrand } =
    React.useContext(FilterContext);

  return (
    <>
      {paginatedBrands.map(
        (brand) =>
          filteredMachineModelByBrand.get(brand) && (
            <BrandRow
              key={`tablebody-brand-row-${brand}`}
              machineModels={
                // TypeScript apparently doesn't narrow this, so we type-cast.
                filteredMachineModelByBrand.get(brand) as MachineModel[]
              }
              brand={brand}
            />
          ),
      )}
    </>
  );
};

const Fade: React.FC<DefaultProps> = ({ children, className }) => (
  <div
    className={`font-sans text-sm text-neutral-500 whitespace-pre ${className}`}
  >
    {children}
  </div>
);

const Pagination: React.FC<DefaultProps> = () => {
  const {
    first,
    last,
    isFirstPage,
    isLastPage,
    currentPage,
    maxPage,
    total,
    setFirstPage,
    setPrevPage,
    setNextPage,
    setLastPage,
  } = React.useContext(FilterContext);

  if (total === 0) {
    return null;
  }

  return (
    <footer
      className={`flex justify-end items-center py-1 mt-2 bg-neutral-50 border border-neutral-300`}
    >
      <Fade className="mr-4">{` ${first} - ${last} / ${total}`}</Fade>
      <IconButton disabled={isFirstPage} size="small" onClick={setFirstPage}>
        <FirstPageIcon />
      </IconButton>
      <IconButton disabled={isFirstPage} size="small" onClick={setPrevPage}>
        <PrevIcon />
      </IconButton>
      <Fade>{` ${currentPage} / ${maxPage}`}</Fade>
      <IconButton disabled={isLastPage} size="small" onClick={setNextPage}>
        <NextIcon />
      </IconButton>
      <IconButton disabled={isLastPage} size="small" onClick={setLastPage}>
        <LastPageIcon />
      </IconButton>
    </footer>
  );
};

export const Table: React.FC<DefaultProps> = () => {
  const isFetching = useSelector(selectIsFetching);

  if (isFetching)
    return (
      <div className="py-6 flex justify-center items-center">
        <CircularProgress color="primary" size={32} />
      </div>
    );

  return (
    <>
      <TableHeader />
      <TableBody />
      <Pagination />
    </>
  );
};
