import {
  EuroSymbol as EuroIcon,
  Language as GlobeIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Divider, Grid } from '@mui/material';
import { Loading } from 'app/components/Loading/Loadable';
import { useTokenInterceptor } from 'app/hooks';
import { environment } from 'environment/environment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PriceHoursGraph } from './Components/PriceHoursGraph/PriceHoursGraph';
import { getContextPrices, getPrice } from './api';
import { brands, countries, hourRanges, years } from './constants';
import { queryParamsToString } from './helpers';
import { picoClient } from './picoClient';
import { ContextPrice, SearchQueryParams } from './types';

import { Notification } from 'app/components/Notification/Loadable';
import { CalculationsTable } from 'app/viewAreas/machines/quickcalculator/Components/CalculationsTable';
import { FindMachineForm } from 'app/viewAreas/machines/quickcalculator/Components/FindMachineForm';
import { HistoricalPriceGraph } from 'app/viewAreas/machines/quickcalculator/Components/HistoricalPriceGraph/HistoricalPriceGraph';
import { InfoBox } from 'app/viewAreas/machines/quickcalculator/Components/InfoBox';
import { PriceTrustLevel } from 'app/viewAreas/machines/quickcalculator/Components/PriceTrustLevel';
import { TrustLevelInfoBox } from 'app/viewAreas/machines/quickcalculator/Components/TrustLevelInfoBox';
import brandModels from './constants/brand_models.json';

const initialQueryParams: SearchQueryParams = {
  brand: undefined,
  model: undefined,
  country: 'Germany',
  year: new Date().getFullYear(),
  hourRange: undefined,
  linkageFront: false,
  ptoFront: false,
  frontLoader: false,
};

export const QuickCalculator: FC = React.memo(() => {
  // getting auth0 token and adding to client
  useTokenInterceptor(picoClient, environment.machines.picoAudience);
  // store the price in the state so that we can update it when the state changes
  const [price, setPrice] = useState<null | number>(null);
  // store trust level in the state so that we can update it when the state changes
  const [trustLevel, setTrustLevel] = useState<null | string>(null);
  // store the context prices in the state so that we can update them when the state changes
  const [contextPrices, setContextPrices] = useState<ContextPrice[]>([]);
  // set loading to true when we are fetching the price
  const [loading, setLoading] = useState(false);
  // set hasCalculation to false when we are fetching the price; set it to true when we have a price
  const [hasCalculation, setHasCalculation] = useState(false);
  // set updating to true when we are updating the state; set it to false when we have tried to fetch the price
  const [updating, setUpdating] = useState(false);
  // set the calculations for the price history table
  const [calculations, setCalculations] = useState<
    { searchQueryParams: SearchQueryParams; price: number; ts: number }[]
  >([]);

  const { search, pathname } = useLocation();
  const { replace } = useHistory();
  const [error, setError] = useState<string | null>(null);

  const searchQuery: SearchQueryParams = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      brand: params.get('brand') ?? initialQueryParams.brand,
      model: params.get('model') ?? initialQueryParams.model,
      country: params.get('country') ?? initialQueryParams.country,
      year: params.has('year')
        ? parseInt(params.get('year') ?? '')
        : initialQueryParams.year,
      hourRange: params.get('hourRange') ?? initialQueryParams.hourRange,
      linkageFront: params.has('linkageFront')
        ? params.get('linkageFront') === 'true'
        : initialQueryParams.linkageFront,
      ptoFront: params.has('ptoFront')
        ? params.get('ptoFront') === 'true'
        : initialQueryParams.ptoFront,
      frontLoader: params.has('frontLoader')
        ? params.get('frontLoader') === 'true'
        : initialQueryParams.frontLoader,
    };
  }, [search]);

  const brandIndex = brands.indexOf(searchQuery.brand ?? '');
  const countryIndex = countries.indexOf(searchQuery.country ?? '');
  const yearIndex = years.indexOf(searchQuery.year ?? new Date().getFullYear());
  const hourRangeIndex = hourRanges.indexOf(searchQuery.hourRange ?? '');
  const brandModelsIndex = searchQuery.brand
    ? brandModels[searchQuery.brand].indexOf(searchQuery.model ?? '')
    : -1;

  const setSearchQueryParams = useCallback(
    (newQp: Partial<SearchQueryParams>) => {
      setUpdating(true);
      replace(
        `${pathname}?${new URLSearchParams(
          queryParamsToString({ ...searchQuery, ...newQp }),
        ).toString()}`,
      );
    },
    [pathname, replace, searchQuery],
  );

  useEffect(() => {
    updatePrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSearchQueryFullyDefined = useMemo(() => {
    if (
      searchQuery.brand &&
      searchQuery.model &&
      searchQuery.country &&
      searchQuery.year &&
      searchQuery.hourRange &&
      [true, false].includes(searchQuery.linkageFront) &&
      [true, false].includes(searchQuery.ptoFront) &&
      [true, false].includes(searchQuery.frontLoader)
    ) {
      return true;
    }
    return false;
  }, [searchQuery]);

  const hasContextPrices = useMemo(() => {
    return contextPrices.length > 0;
  }, [contextPrices]);

  const updateContextPrices = useCallback(() => {
    setContextPrices([]);
    const getContextPricesAsync = async () => {
      try {
        setLoading(true);
        const prices = await getContextPrices({
          brand: searchQuery.brand as string,
          model: searchQuery.model as string,
          year: searchQuery.year as number,
        });

        setContextPrices(prices);
      } catch (error) {
        setError((error as any).message);
      }
      setLoading(false);
    };

    if (searchQuery.brand && searchQuery.model && searchQuery.year) {
      getContextPricesAsync();
    }
  }, [searchQuery]);

  const updatePrice = useCallback(() => {
    if (isSearchQueryFullyDefined) {
      setHasCalculation(false);
      setContextPrices([]);

      const getPriceAsync = async () => {
        try {
          setLoading(true);
          const { price, trustLevel } = await getPrice(searchQuery);
          setPrice(price);
          setHasCalculation(true);
          setTrustLevel(trustLevel);
          if (price) {
            setCalculations([
              { searchQueryParams: searchQuery, price, ts: Date.now() },
              ...calculations,
            ]);
            updateContextPrices();
          }
          setLoading(false);
          setUpdating(false);
        } catch (error) {
          setError((error as any).message || error);
          console.error(error);
          setPrice(null);
          setTrustLevel(null);
          setLoading(false);
          setUpdating(false);
        }
      };
      getPriceAsync();
    }
  }, [
    calculations,
    searchQuery,
    isSearchQueryFullyDefined,
    updateContextPrices,
  ]);

  const isStateInitialState = useMemo(() => {
    if (
      searchQuery.brand === initialQueryParams.brand &&
      searchQuery.model === initialQueryParams.model &&
      searchQuery.country === initialQueryParams.country &&
      searchQuery.year === initialQueryParams.year &&
      searchQuery.hourRange === initialQueryParams.hourRange &&
      searchQuery.linkageFront === initialQueryParams.linkageFront &&
      searchQuery.ptoFront === initialQueryParams.ptoFront &&
      searchQuery.frontLoader === initialQueryParams.frontLoader
    ) {
      return true;
    }
    return false;
  }, [searchQuery]);

  const ifShouldRenderPriceGraph =
    price && searchQuery.hourRange && !updating && !loading && hasContextPrices;

  return (
    <div>
      <Loading isLoading={loading} />
      <Notification
        open={Boolean(error)}
        message={error ?? ''}
        severity={'error'}
        handleClose={() => setError(null)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InfoBox />
        </Grid>
        <Grid item xs={12}>
          <PriceTrustLevel
            searchQueryParams={searchQuery}
            loading={loading}
            trustLevel={trustLevel}
            price={price}
            updating={updating}
            isStateInitialState={isStateInitialState}
          />
        </Grid>
        <Grid item xs={12}>
          <TrustLevelInfoBox />
        </Grid>
        <FindMachineForm
          state={searchQuery}
          setSearchQueryParams={setSearchQueryParams}
          brandIndex={brandIndex}
          brandModelsIndex={brandModelsIndex}
          countryIndex={countryIndex}
          hourRangeIndex={hourRangeIndex}
          yearIndex={yearIndex}
        />
        <Grid container item>
          <Grid item xs={6} />
          <Grid item xs={4}>
            <Button
              disabled={isStateInitialState}
              variant="outlined"
              sx={{ marginLeft: '12px' }}
              onClick={() => {
                setSearchQueryParams(initialQueryParams);
                setPrice(null);
              }}
            >
              Clear
            </Button>
            <Button
              disabled={!price}
              variant="outlined"
              sx={{ marginLeft: '12px' }}
              startIcon={<GlobeIcon />}
              href={`https://e-farm.com/en/used-farm-machinery/?${queryParamsToString(
                {
                  machineType: 'tractor',
                  brand: searchQuery.brand?.toLowerCase(),
                  model: searchQuery.model + '-mapped',
                  'year-of-production': `${years[0]}-${
                    years[years.length - 1]
                  }`,
                  'engine-hours': searchQuery.hourRange ?? undefined,
                },
              )}`}
              target="_blank"
            >
              Search online
            </Button>
          </Grid>
          <Grid item xs={2} justifyContent="flex-end" display="flex">
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              loadingPosition="start"
              disabled={loading || !isSearchQueryFullyDefined}
              onClick={updatePrice}
              startIcon={<EuroIcon />}
            >
              Calculate
            </LoadingButton>
          </Grid>
        </Grid>
        {!!(hasCalculation && !price && !updating) && (
          <Grid item xs={12}>
            {
              <Alert severity="error">
                No price could be calculated - Please check your inputs
              </Alert>
            }
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {ifShouldRenderPriceGraph && (
          <>
            <Grid item xs={12} mb={'50px'}>
              <PriceHoursGraph
                hasContextPrices={hasContextPrices}
                contextPrices={contextPrices}
                state={searchQuery}
                price={price}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
        {contextPrices.length >= 1 && (
          <>
            <Grid item xs={12} mb={'50px'}>
              <HistoricalPriceGraph
                contextPrices={contextPrices}
                predictedPrice={price}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}

        {!!calculations.length && (
          <CalculationsTable
            calculations={calculations}
            setSearchQueryParams={setSearchQueryParams}
            setPrice={setPrice}
          />
        )}
      </Grid>
    </div>
  );
});
