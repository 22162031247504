import ClearIcon from '@mui/icons-material/Clear';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectHasAnyData, selectQuery } from './selectors';
import { actions } from './state';
import { DefaultProps } from 'utils/react';

export const QueryModel: React.FC<DefaultProps> = () => {
  const query = useSelector(selectQuery);
  const dispatch = useDispatch();
  const clearQuery = React.useCallback(
    () => dispatch(actions.clearQuery()),
    [dispatch],
  );
  const setQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      dispatch(
        actions.query(
          event.target.value.length ? event.target.value : undefined,
        ),
      ),
    [dispatch],
  );
  const hasAnyData = useSelector(selectHasAnyData);

  return (
    <FormControl
      className={`w-56 ml-2 ${query ? 'bg-yellow-200' : ''}`}
      size="small"
      variant="outlined"
      disabled={!hasAnyData}
    >
      <InputLabel htmlFor="filter-models">Search Models</InputLabel>
      <OutlinedInput
        className="rounded-none"
        id="filter-models"
        size="small"
        label="Search Models"
        endAdornment={
          query ? (
            <InputAdornment position="end">
              <IconButton onClick={clearQuery} edge="end">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
        value={query || ''}
        onChange={setQuery}
      />
    </FormControl>
  );
};
