import axios from 'axios';
import { environment } from 'environment/environment';
import { ReportFormat } from './ReportFormat';
import { ReportLanguage } from './ReportLanguage';
import { ReportType } from './ReportType';

export const generateReportUrl = (
  type: ReportType,
  params: {
    format: ReportFormat;
    language: ReportLanguage;
    machineId: string;
    name: string | null;
    email: string | null;
    phone: string | null;
    profilePicture: string | null;
  },
): Promise<string> => {
  const urlParams = new URLSearchParams();
  urlParams.append('lang', params.language);

  let url: string;

  if (type === ReportType.HTML) {
    url = `${environment.machines.pdfServiceUrl}/html/encrypt/${params.machineId}`;
  } else {
    url = `${environment.machines.pdfServiceUrl}/pdf/long/${params.machineId}`;
  }

  switch (params.format) {
    case ReportFormat.Basic:
      urlParams.append('excludeSources', 'mia,vin');
      break;
    case ReportFormat.InspectionDekra:
      urlParams.append('source', 'mia');
      urlParams.append('inspector', 'dekra');
      break;
    case ReportFormat.InspectionNonDekra:
      urlParams.append('source', 'mia');
      break;
    case ReportFormat.VinEnhanced:
      urlParams.append('excludeSources', 'mia');
      break;
  }

  if (params.name) {
    urlParams.append('name', params.name);
  }
  if (params.email) {
    urlParams.append('email', params.email);
  }
  if (params.phone) {
    urlParams.append('phone', params.phone);
  }
  if (params.profilePicture) {
    urlParams.append('profilePicture', params.profilePicture);
  }

  const finalUrl = `${url}?${urlParams.toString()}`;

  if (type === ReportType.PDF) {
    return Promise.resolve(finalUrl);
  }

  return axios.get(finalUrl).then(({ data }) => data);
};
