/* eslint-disable array-callback-return, @typescript-eslint/no-redeclare */
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import React, { useEffect } from 'react';
import * as flex from 'flexsearch';
import { useDispatch, useSelector } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as MMD from '@it-efarm/model-metadata';

import * as api from './model-metadata.client';
import {
  Brand,
  FilterState as FilterState,
  deriveExpansionState,
  ExpansionState,
  ID,
  Index,
  initialState,
  MachineModel,
  recordFromConst,
  createInitialFilterState,
  Failure,
  FailureID,
} from './domain';
import {
  selectAllBrands,
  selectFilteredBrands,
  selectMachineModelsByBrand,
  selectQuery,
} from './selectors';
import { AxiosResponse } from 'axios';

function* rootSaga() {
  yield takeLatest(actions.persistChangesTrigger.type, persistChanges);
  yield takeLatest(actions.getMachineModelsTrigger.type, getMachineModels);
}

function* persistChanges({
  payload: machineModel,
}: PayloadAction<MachineModel>) {
  const response = yield call(
    api.postMachineModel,
    MachineModel.toDto(machineModel),
  );

  if (response.status === 201) {
    yield put(
      actions.persistChangesSuccess(MachineModel.fromDto(response.data)),
    );
  } else {
    const { brand, model } = machineModel;
    yield put(
      actions.failWith(
        Failure.create({
          message: `Failed to save ${brand}/${model}`,
        }),
      ),
    );
  }
}

function* getMachineModels() {
  try {
    const response: AxiosResponse<MMD.api.MachineModelDto[], any> = yield call(
      api.getMachineModels,
    );

    if (response.status === 200) {
      const idsByBrand: Record<Brand, ID[]> = {};
      const allBrands = new Set<Brand>();
      const machineModelById: Record<ID, MachineModel> = {};

      for (const dto of response.data) {
        const machineModel = MachineModel.fromDto(dto);
        const brandIds = idsByBrand[dto.brand] || [];

        machineModelById[machineModel.id] = machineModel;
        brandIds.push(machineModel.id);

        idsByBrand[dto.brand] = brandIds;
        allBrands.add(machineModel.brand);
      }

      yield put(
        actions.getMachineModelsSuccess({
          allBrands: Array.from(allBrands).sort(),
          idsByBrand: idsByBrand,
          machineModelById,
        }),
      );
    } else {
      yield put(
        actions.failWith(
          Failure.create({
            message:
              'Failed to populate the table. Please reload the page, or try again later.',
          }),
        ),
      );
    }
  } catch (e) {
    yield put(
      actions.failWith(
        Failure.create({
          message:
            'Network issue when populating the table. Please reload the page, or try again later.',
        }),
      ),
    );
  }
}

const slice = createSlice({
  name: 'machineModels',
  initialState,
  reducers: {
    updateMachineModelData(
      state,
      {
        payload: { key, id, value },
      }: PayloadAction<{
        key: string;
        id: ID;
        value: MMD.domain.Value<unknown>;
      }>,
    ) {
      if (!state.diffById[id]) {
        state.diffById[id] = {};
      }
      state.diffById[id][key] = value;
    },
    setIdsByBrand(state, { payload }: PayloadAction<Record<Brand, ID[]>>) {
      state.idsByBrand = payload;
    },
    clearFilteredMachineModels(state) {
      state.filteredIdsByBrand = {};
    },
    setFilteredMachineModels(
      state,
      { payload }: PayloadAction<Record<Brand, ID[]>>,
    ) {
      state.filteredIdsByBrand = payload;
    },
    setAllBrands(state, { payload }: PayloadAction<Brand[]>) {
      state.allBrands = payload;
      state.expandedBrands = recordFromConst(payload, true);
    },
    toggleAllBrands(state) {
      switch (deriveExpansionState(state.expandedBrands)) {
        case ExpansionState.SOME:
          state.expandedBrands = recordFromConst(state.allBrands, false);
          break;
        case ExpansionState.ALL:
          state.expandedBrands = recordFromConst(state.allBrands, false);
          break;
        case ExpansionState.NONE: {
          state.expandedBrands = recordFromConst(state.allBrands, true);
          break;
        }
      }
    },
    toggleBrand(state, { payload: brand }: PayloadAction<Brand>) {
      state.expandedBrands[brand] = !state.expandedBrands[brand];
    },
    query(state, { payload: query }: PayloadAction<string | undefined>) {
      state.query = query;
    },
    clearQuery(state) {
      state.query = undefined;
    },
    filterBrands(state, { payload: filteredBrands }: PayloadAction<Brand[]>) {
      state.filteredBrands = filteredBrands;
    },
    clearFilteredBrands(state) {
      state.filteredBrands = [];
    },
    resetAllChanges(state) {
      state.diffById = {};
    },
    persistChangesTrigger(_state, _action: PayloadAction<MachineModel>) {
      return undefined;
    },
    persistChangesSuccess(state, { payload }: PayloadAction<MachineModel>) {
      delete state.diffById[payload.id];
      state.machineModelById[payload.id] = payload;
    },
    getMachineModelsTrigger(state) {
      state.isFetching = true;
    },
    getMachineModelsSuccess(
      state,
      {
        payload: { allBrands, idsByBrand, machineModelById },
      }: PayloadAction<{
        allBrands: Brand[];
        idsByBrand: Record<Brand, ID[]>;
        machineModelById: Record<ID, MachineModel>;
      }>,
    ) {
      state.isFetching = false;
      state.idsByBrand = idsByBrand;
      state.allBrands = allBrands;
      state.machineModelById = machineModelById;
      state.expandedBrands = recordFromConst(allBrands, false);
    },
    getMachineModelsFailure(_state) {
      return undefined;
    },
    resetChanges(state, { payload: id }: PayloadAction<ID>) {
      delete state.diffById[id];
    },
    failWith(state, { payload }: PayloadAction<Failure>) {
      state.failureById[payload.id] = payload;
      state.visibleFailuresIds.push(payload.id);
      state.isFetching = false;
    },
    hideFailure(state, { payload }: PayloadAction<FailureID>) {
      state.visibleFailuresIds = state.visibleFailuresIds.filter(
        (id) => id !== payload,
      );
    },
  },
});

export const { actions } = slice;

export const useSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: rootSaga });
};

export const useInitialize = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getMachineModelsTrigger());
  }, [dispatch]);
};

export const FilterContext = React.createContext<FilterState>(
  createInitialFilterState(),
);

/**
 * Exposes pagination and filtered machine models.
 *
 * Usually this would be done as part of redux state,
 * but our indices can't be persisted in redux (unserializable),
 * and our pagination and machine model search depend on the indices.
 *
 * There is some optimization potential here, separating the search state from
 * pagination state, hopefully reducing the number of renders.
 */
export function useFilterState(): FilterState {
  // =============================================================================
  // indexing
  // =============================================================================
  const dispatch = useDispatch();
  const machineModels = useSelector(selectMachineModelsByBrand);
  const allBrands = useSelector(selectAllBrands);
  const [indices, setIndices] = React.useState(new Map());

  React.useEffect(() => {
    const _indices: Map<Brand, Index> = new Map();

    for (const brand of allBrands) {
      const brandIndex =
        _indices.get(brand) ||
        new flex.Document<MachineModel>({
          document: {
            id: 'id',
            index: [{ field: 'model', tokenize: 'reverse' }],
            // Type-casting due to typing issue in flexsearch.
            store: true as false,
          },
        });

      for (const machineModel of machineModels[brand]) {
        brandIndex.add(machineModel);
      }

      _indices.set(brand, brandIndex);
    }

    setIndices(_indices);
  }, [dispatch, machineModels, allBrands]);

  // =============================================================================
  // filtering machine models
  // =============================================================================

  const currentQuery = useSelector(selectQuery);
  const filteredBrands = useSelector(selectFilteredBrands);
  const machineModelByBrand = useSelector(selectMachineModelsByBrand);

  const { filteredBrandsWithSearchResults, filteredMachineModelByBrand } =
    React.useMemo((): {
      filteredMachineModelByBrand: Map<Brand, MachineModel[]>;
      filteredBrandsWithSearchResults: Brand[];
    } => {
      const filteredMachineModelByBrand = new Map<Brand, MachineModel[]>();
      const filteredBrandsWithSearchResults: Brand[] = [];

      for (const brand of filteredBrands) {
        const index = indices.get(brand);
        // By default, we show everything.
        if (index === undefined || currentQuery === undefined) {
          filteredMachineModelByBrand.set(brand, machineModelByBrand[brand]);
          filteredBrandsWithSearchResults.push(brand);
        } else {
          const searchResults = index
            .search(currentQuery, { enrich: true })
            .map((r) =>
              r.result.map((m) => {
                // flexsearch is poorly typed. When `enrich: true`, the stored
                // document is returned as well.
                return (m as unknown as { doc: MachineModel }).doc;
              }),
            );

          const results: MachineModel[] | undefined = searchResults[0];

          // flexsearch returns results for every indexed field separately, since
          // we're only indexing one (at the moment of writing this), we just return
          // the first.
          filteredMachineModelByBrand.set(brand, results || []);

          // We only include the brand, if we have results from the query.
          if (results !== undefined && results.length > 0) {
            filteredBrandsWithSearchResults.push(brand);
          }
        }
      }

      return {
        filteredMachineModelByBrand,
        filteredBrandsWithSearchResults,
      };
    }, [filteredBrands, machineModelByBrand, currentQuery, indices]);

  // =============================================================================
  // pagination
  // =============================================================================

  const [currentPage, setCurrentPage] = React.useState(1);

  const derivedPaginationState = React.useMemo(() => {
    const maxPerPage = 15;
    const maxPage = Math.ceil(
      filteredBrandsWithSearchResults.length / maxPerPage,
    );
    const indexOfLast = currentPage * maxPerPage;
    const indexOfFirst = indexOfLast - maxPerPage;

    // This is the main reason pagination state lives here, instead of redux.
    // It relies on `filteredBrands`, which in turn relies on search indices.
    const paginatedBrands = filteredBrandsWithSearchResults.slice(
      indexOfFirst,
      indexOfLast,
    );

    const first = indexOfFirst + 1;
    const last = indexOfFirst + paginatedBrands.length;

    const total = filteredBrandsWithSearchResults.length;
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === maxPage;

    return {
      maxPage,
      paginatedBrands,
      first,
      last,
      total,
      isFirstPage,
      isLastPage,
    };
  }, [filteredBrandsWithSearchResults, currentPage]);

  // When we filter machine models or brands, the currentPage may be already
  // set higher than that, so we have to reset it.
  React.useEffect(() => {
    if (
      currentPage > derivedPaginationState.maxPage &&
      derivedPaginationState.maxPage > 0
    ) {
      setCurrentPage(derivedPaginationState.maxPage);
    }
  }, [currentPage, derivedPaginationState.maxPage]);

  const setFirstPage = React.useCallback(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [currentPage]);

  const setPrevPage = React.useCallback(() => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const setNextPage = React.useCallback(() => {
    if (currentPage !== derivedPaginationState.maxPage) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, derivedPaginationState.maxPage]);

  const setLastPage = React.useCallback(() => {
    if (currentPage !== derivedPaginationState.maxPage) {
      setCurrentPage(derivedPaginationState.maxPage);
    }
  }, [currentPage, derivedPaginationState.maxPage]);

  return {
    setFirstPage,
    setPrevPage,
    setNextPage,
    setLastPage,
    currentPage,
    filteredMachineModelByBrand,
    ...derivedPaginationState,
  };
}
