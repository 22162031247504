import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { App } from 'app';
import { environment } from 'environment/environment';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import reportWebVitals from 'reportWebVitals';
import 'sanitize.css/sanitize.css';
import { configureAppStore } from 'store/configureStore';
import { ThemeProvider } from 'theme';

Sentry.init({
  dsn: environment.sentryDNS,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: environment.nodeEnv,
});

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <Auth0Provider
        domain={environment.auth0.domain}
        clientId={environment.auth0.clientId}
        redirectUri={window.location.origin}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Auth0Provider>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
