import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectHasAnyChanges } from './selectors';
import { actions } from './state';
import { DefaultProps } from 'utils/react';

export const ResetAllChanges: React.FC<DefaultProps> = () => {
  const hasAnyChanges = useSelector(selectHasAnyChanges);
  const dispatch = useDispatch();
  const resetAllChanges = React.useCallback(
    () => dispatch(actions.resetAllChanges()),
    [dispatch],
  );

  if (!hasAnyChanges) {
    return null;
  }

  return (
    <Button
      className="ml-2 rounded-none border-orange-500 hover:border-orange-600 text-orange-500"
      onClick={resetAllChanges}
      variant="outlined"
      startIcon={<RestartAltIcon />}
    >
      Reset All Changes
    </Button>
  );
};
