export const storage = {
  storeObject: (key: string, value: any): void => {
    const jsonValue = JSON.stringify(value);
    storage.storeValue(key, jsonValue);
  },
  getObject: (key: string): any => {
    const jsonValue = storage.getValue(key);
    return jsonValue !== null ? JSON.parse(jsonValue) : null;
  },
  storeValue: (key: string, value: string): void => {
    try {
      window.localStorage.setItem(key, value);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  },
  getValue: (key: string): string | null => {
    try {
      return window.localStorage.getItem(key);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    }
  },
};
