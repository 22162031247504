import { Alert, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { DefaultProps } from 'utils/react';

type Info = {
  id: string;
  message: string;
  type: 'info' | 'warning';
};

const defaultInfos: Info[] = [
  {
    id: 'performance',
    message:
      'This page may take a few seconds to load, as it effectively fetches the entire model metadata database.',
    type: 'info',
  },
  {
    id: 'updating',
    message:
      'Saving a change on a machine model will immediately trigger an update to all machines of that model.',
    type: 'info',
  },
];

export const Infos: React.FC<DefaultProps> = () => {
  const [infos, setInfos] = useState<Info[]>(defaultInfos);

  return (
    <>
      {infos.map(({ id, message, type }, index) => (
        <Alert
          key={`info-${id}`}
          className="mt-2 text-gray-500"
          severity={type}
          action={
            <IconButton
              onClick={() =>
                setInfos((infos) => infos.filter((_, i) => i !== index))
              }
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <Typography>{message}</Typography>
        </Alert>
      ))}
    </>
  );
};
