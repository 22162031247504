import React from 'react';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { formatPrice } from 'utils/format/formatPrice';

export const CustomScatterplotTooltip = ({
  payload,
  active,
}: TooltipProps<ValueType, NameType>): JSX.Element | null => {
  if (active && payload && payload.length) {
    const { brandName, hours, mappedModel, unitPrice, year } =
      payload[0]?.payload ?? {};
    return (
      <div
        style={{
          backgroundColor: 'rgba(97, 214, 58)',
          padding: '10px',
          opacity: 0.8,
        }}
      >
        <>
          <p>Brand Name: {brandName}</p>
          <p>Hours: {hours}</p>
          <p>MappeModel: {mappedModel}</p>
          <p>UniPrice: {formatPrice(unitPrice)}</p>
          <p>Year: {year}</p>
        </>
      </div>
    );
  } else {
    return null;
  }
};
