import { z } from 'zod';
import axios from 'axios';

import { environment } from 'environment/environment';

// =============================================================================
// Schemata
// =============================================================================

const ValueSchema = z.union([
  z.boolean(),
  z.number(),
  z.string().array(),
  z.string(),
  z.undefined(),
  z.record(z.unknown()),
]);

export type Value = z.infer<typeof ValueSchema>;

const AnyDescriptionSchema = z.object({
  kind: z.string().min(1),
  values: z.record(z.string().min(1), ValueSchema),
  timestamp: z.number(),
});

export const ModelSchema = z
  .object({
    BRAND: z.string().min(1),
    MACHINE_TYPE: z.string().optional(),
    SUB_TYPE: z.string().min(1).optional(),
    MODEL: z.string().min(1),
    normalizedModel: z.string().min(1),
    normalizedModelWords: z.string().min(1).array(),
    modelErrorPenality: z.number(),
    MODEL_EXTENSION: z.string().min(1).optional(),
    MODEL_SERIES: z.string().min(1).optional(),
    BASE_MODEL: z.string().min(1),
    baseModelSeries: z.string().min(1).optional(),
    baseModelSubType: z.string().min(1).optional(),
    normalizedBaseModel: z.string().min(1),
    normalizedBaseModelWords: z.string().min(1).array(),
    baseModelErrorPenality: z.number(),
    isAlias: z.boolean(),
    machineCount: z.number().int().optional(),
    TRANSMISSION_TYPE: z.string().optional(),
    ENGINE_HP: z.number().optional(),
    YEAR_OF_PRODUCTION_START: z.number().int().optional(),
    YEAR_OF_PRODUCTION_END: z.number().int().optional(),
    position: z.number().int(),
  })
  .transform(
    ({
      BRAND,
      MACHINE_TYPE,
      SUB_TYPE,
      MODEL,
      normalizedModel,
      MODEL_EXTENSION,
      MODEL_SERIES,
      BASE_MODEL,
      baseModelSeries,
      baseModelSubType,
      normalizedBaseModel,
      isAlias,
      machineCount,
      TRANSMISSION_TYPE,
      ENGINE_HP,
      YEAR_OF_PRODUCTION_START,
      YEAR_OF_PRODUCTION_END,
      position,
    }) => ({
      BRAND,
      MACHINE_TYPE,
      SUB_TYPE,
      MODEL,
      normalizedModel,
      MODEL_EXTENSION,
      MODEL_SERIES,
      BASE_MODEL,
      baseModelSeries,
      baseModelSubType,
      normalizedBaseModel,
      isAlias,
      machineCount,
      TRANSMISSION_TYPE,
      ENGINE_HP,
      YEAR_OF_PRODUCTION_START,
      YEAR_OF_PRODUCTION_END,
      position,
    }),
  );

const DescriptionSchema = AnyDescriptionSchema;

export type Description = z.infer<typeof DescriptionSchema>;

const DescriptionsSchema = DescriptionSchema.array();

export const ParseModelParamsSchema = z.object({
  BRAND: z.string().min(1),
  RAW_MODEL: z.string().min(1),
  MACHINE_TYPE: z.string().min(1),
  TRANSMISSION_TYPE: z.string().min(1).optional(),
  YEAR_OF_PRODUCTION: z
    .string()
    .min(1)
    .pipe(z.coerce.number().int())
    .optional(),
  ENGINE_HP: z.string().min(1).pipe(z.coerce.number().int()).optional(),
});

export type ParseModelParams = z.infer<typeof ParseModelParamsSchema>;

// =============================================================================
// Client
// =============================================================================

export const client = axios.create({
  baseURL: environment.machineSearch.baseUrl,
  timeout: 1_000,
});

export const parseModel = (params: ParseModelParams): Promise<Description[]> =>
  client
    .get<Description[]>(`/api/v1/models/parse`, {
      params: { ...params, describe: true },
    })
    .then(({ data }) => {
      const r = DescriptionsSchema.safeParse(data);
      if (r.success) {
        return r.data;
      } else {
        console.error(r.error.toString());
        return [];
      }
    });

export const getBrands = (): Promise<string[]> =>
  client
    .get<Description[]>(`/api/v1/brands`)
    .then(({ data }) => z.string().array().parse(data));
