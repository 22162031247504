export const colorPrimary = {
  main: '#448539',
  light: '#8CC63F',
  dark: '#4D923D',
  contrastText: '#fff',
};

export const colorSecondary = {
  main: '#FFB826',
  light: '#FFD480',
  dark: '#F5A300',
  contrastText: '#fff',
};

export const colorWarning = {
  main: '#ED6C02',
  light: '#FFB547',
  dark: '#C77700',
  contrastText: '#fff',
};

export const colorError = {
  main: '#F44336',
  light: '#F88078',
  dark: '#E31B0C',
  contrastText: '#fff',
};

export const colorInfo = {
  main: '#2196F3',
  light: '#64B6F7',
  dark: '#0B79D0',
  contrastText: '#fff',
};

export const colorText = {
  primary: '#000000DE',
  secondary: '#00000099',
  disabled: '#00000061',
};
