export const salesManager = {
  'forgest.potka@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Fanis_Potka_DDDIW-anH2.jpg?tr=e-grayscale',
  'guillermo.fidalgo@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Guillermo_Fidalgo_atUBhARSI.jpg?tr=e-grayscale',
  'jens.grawe@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Jens_Grawe_SXxE2MsDB3.jpg?tr=e-grayscale',
  'johann.stolberg@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Johann_Stolberg_cT4FcEyL7O.jpg?tr=e-grayscale',
  'marco.zane@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Marco_Zane_5T3iFFhY0.jpg?tr=e-grayscale',
  'maxim.talpa@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Maxim_Talpa_G-nYR051sy.jpg?tr=e-grayscale',
  'istvan.pasztor@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Istvan-Pasztor_qroYPGwNr.jpg?tr=e-grayscale',
  'petar.moryanov@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Petar_Moryanov_BG_5vAjfEHjI.jpg',
  'nemanja.arsic@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Nemanja_Arsic_SL_QEV-2xmtH.jpg',
  'morten.jorgensen@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Morten_J%C3%B8rgensen_nMikRsyUV_ghf5-ZUFZ.jpeg',
  'alexandra.datta@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Alexandra_Datta_y520gzuyya_Xl91lVbiL.png?tr=e-grayscale',
  'mike.bewersdorf@e-farm.com':
    'https://ik.imagekit.io/efarm/sales-manager/Mike-Bewersdorf_VZ_2ykAmy.jpg?tr=e-grayscale',
};
