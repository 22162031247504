import { createTheme, responsiveFontSizes } from '@mui/material';
import { colorError, colorPrimary, colorSecondary, colorText } from 'theme';

let theme = createTheme({
  palette: {
    primary: colorPrimary,
    secondary: colorSecondary,
    error: colorError,
    text: colorText,
  },
});

theme = responsiveFontSizes(theme);

export { theme };
