import { z } from 'zod';
export const Lifetime = z.nativeEnum({
    /** Unlikely to be replaced, but not impossible. May be used for machine-specific data. */
    STABLE: 'stable',
    /** Likely to be replaced. Primarily use for model series information. */
    VOLATILE: 'volatile',
    /** We don't know. */
    UNKNOWN: 'unknown',
});
export const Kind = z.nativeEnum({
    VALUE: 'value',
    OPTIONS: 'options',
});
const baseParser = z.object({
    isStandardEquipment: z.boolean(),
    kind: z.literal(Kind.enum.VALUE),
    lifetime: Lifetime,
});
export const Value = {
    is: (_) => _.kind === Kind.enum.VALUE,
    parser: {
        string: baseParser.extend({ value: z.string() }),
        number: baseParser.extend({ value: z.number() }),
        boolean: baseParser.extend({ value: z.boolean() }),
    },
    create: ({ lifetime, value, isStandardEquipment, }) => ({
        isStandardEquipment,
        kind: Kind.enum.VALUE,
        lifetime,
        value,
    }),
    createStandardEquipment: (value) => ({
        isStandardEquipment: true,
        kind: Kind.enum.VALUE,
        lifetime: Lifetime.enum.UNKNOWN,
        value,
    }),
    createNonStandardEquipment: (value) => ({
        isStandardEquipment: false,
        kind: Kind.enum.VALUE,
        lifetime: Lifetime.enum.UNKNOWN,
        value,
    }),
};
export const Options = {
    is: (_) => _.kind === Kind.enum.OPTIONS,
    create: ({ options, standardEquipment, }) => ({
        kind: Kind.enum.OPTIONS,
        standardEquipment,
        options,
    }),
};
