// language codes must match with POEditor: https://poeditor.com/projects/view?id=368497
export enum ReportLanguage {
  bg = 'bg',
  cs = 'cs',
  de = 'de',
  el = 'el',
  en = 'en',
  es = 'es',
  fr = 'fr',
  hu = 'hu',
  it = 'it',
  pl = 'pl',
  ro = 'ro',
  ru = 'ru',
  sl = 'sl',
  da = 'da',
  sr = 'sr',
}
