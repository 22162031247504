import { useAuth0 } from '@auth0/auth0-react';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { storage } from 'utils/storage';

const audiencesStorageKey = 'authenticatedAudiences';

export const useTokenInterceptor = (
  client: AxiosInstance,
  audience: string,
) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated } =
    useAuth0();

  useEffect(() => {
    let id = -1;

    if (isAuthenticated) {
      id = client.interceptors.request.use(
        async (config) =>
          await getTokenWithRetries(
            config,
            audience,
            getAccessTokenSilently,
            getAccessTokenWithPopup,
            3,
          ),
      );
    }

    return () => {
      client.interceptors.request.eject(id);
    };
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    client,
    audience,
    getAccessTokenWithPopup,
  ]);
};

const getTokenWithRetries = async (
  config: AxiosRequestConfig<any>,
  audience: string,
  getAccessTokenSilently: ({ audience }) => Promise<string>,
  getAccessTokenWithPopup: ({ audience }) => Promise<string>,
  retries: number,
) => {
  if (retries === 0) {
    removeAuthenticatedAudience(audience);
    return config;
  }

  try {
    return await getToken(
      config,
      audience,
      getAccessTokenSilently,
      getAccessTokenWithPopup,
    );
  } catch (error: any) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return await getTokenWithRetries(
      config,
      audience,
      getAccessTokenSilently,
      getAccessTokenWithPopup,
      --retries,
    );
  }
};

const getToken = (
  config: AxiosRequestConfig<any>,
  audience: string,
  getAccessTokenSilently: ({ audience }) => Promise<string>,
  getAccessTokenWithPopup: ({ audience }) => Promise<string>,
): Promise<AxiosRequestConfig<any>> =>
  isAudienceAuthenticated(audience)
    ? getTokenWithFunc(config, audience, getAccessTokenSilently)
    : getTokenWithFunc(config, audience, getAccessTokenWithPopup);

const getTokenWithFunc = async (
  config: AxiosRequestConfig<any>,
  audience: string,
  getTokenFunc: ({ audience }) => Promise<string>,
): Promise<AxiosRequestConfig<any>> => {
  const token = await getTokenFunc({ audience });
  if (isTokenValid(token)) {
    ensureAudienceIsStored(audience);
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  };
};

const isTokenValid = (token: string): boolean => token.includes('.');

const isAudienceAuthenticated = (audience: string): boolean =>
  getAuthenticatedAudiences().includes(audience);

const getAuthenticatedAudiences = (): string[] =>
  storage.getObject(audiencesStorageKey) ?? [];

const removeAuthenticatedAudience = (audience: string) =>
  storage.storeObject(
    audiencesStorageKey,
    getAuthenticatedAudiences().filter((a) => a !== audience),
  );

const ensureAudienceIsStored = (audience: string): void => {
  const authenticatedAudiences = getAuthenticatedAudiences();
  if (!authenticatedAudiences.includes(audience)) {
    storage.storeObject(audiencesStorageKey, [
      ...authenticatedAudiences,
      audience,
    ]);
  }
};
