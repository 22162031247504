// [IMPORT NEW VIEWAREA ABOVE] < Needed for generating views seamlessly
import { viewArea as models } from './machine-models/viewArea';
import { TViewArea } from 'app/viewAreas/TViewArea';
import { viewArea as dealers } from './dealers/viewArea';
import { viewArea as machines } from './machines/viewArea';
import { viewArea as qms } from './qms/viewArea';
import { viewArea as smarttrade } from './smarttrade/viewArea';

export const viewAreas: TViewArea[] = [
  dealers,
  smarttrade,
  machines,
  models,
  qms,
  // [INSERT NEW VIEWAREA ABOVE] < Needed for generating views seamlessly
];

export const getViewAreas = () => viewAreas;
