import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import {
  brands,
  countries,
  hourRanges,
  years,
} from 'app/viewAreas/machines/quickcalculator/constants';
import { SearchQueryParams } from 'app/viewAreas/machines/quickcalculator/types';
import React from 'react';

import brandModels from 'app/viewAreas/machines/quickcalculator/constants/brand_models.json';

type Props = {
  state: SearchQueryParams;
  setSearchQueryParams: (qp: Partial<SearchQueryParams>) => void;
  brandIndex: number;
  brandModelsIndex: number;
  countryIndex: number;
  hourRangeIndex: number;
  yearIndex: number;
};

export const FindMachineForm = ({
  state,
  setSearchQueryParams,
  brandIndex,
  brandModelsIndex,
  countryIndex,
  hourRangeIndex,
  yearIndex,
}: Props): JSX.Element => {
  return (
    <>
      <Grid container item xs={6} spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel variant="outlined" htmlFor="brand-select">
              Brand
            </InputLabel>
            <Select
              name="brand-select"
              id="brand-select"
              placeholder="Select brand"
              displayEmpty
              onChange={(ev) =>
                setSearchQueryParams({
                  brand: brands[parseInt(ev.target.value.toString())],
                  model: undefined,
                })
              }
              label="Brand"
              value={brandIndex}
            >
              {brands.map((brand, index) => (
                <MenuItem
                  key={brand}
                  value={index}
                  selected={index === brandIndex}
                >
                  {brand}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel variant="outlined" htmlFor="model-select">
              Model
            </InputLabel>
            <Select
              name="model-select"
              id="model-select"
              placeholder="Select model"
              displayEmpty
              onChange={(ev) =>
                setSearchQueryParams({
                  model: state.brand
                    ? brandModels[state.brand][
                        parseInt(ev.target.value.toString())
                      ]
                    : undefined,
                })
              }
              label="Model"
              value={brandModelsIndex}
            >
              <MenuItem value={-1}>
                <em></em>
              </MenuItem>
              {state.brand ? (
                brandModels[state.brand].map((brandModel, index) => (
                  <MenuItem
                    key={brandModel}
                    value={index}
                    selected={index === brandModelsIndex}
                  >
                    {brandModel}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={-1}>Please select a brand first</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={6} spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel variant="outlined" htmlFor="country-select">
              Country
            </InputLabel>
            <Select
              name="country-select"
              id="country-select"
              placeholder="Select country"
              displayEmpty
              onChange={(ev) =>
                setSearchQueryParams({
                  country: countries[parseInt(ev.target.value.toString())],
                })
              }
              label="Country"
              value={countryIndex}
            >
              <MenuItem value={-1} />
              {countries.map((country, index) => (
                <MenuItem
                  key={country}
                  value={index}
                  selected={index === countryIndex}
                >
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel variant="outlined" htmlFor="hourRange-select">
              Hour Range
            </InputLabel>
            <Select
              name="hourRange-select"
              id="hourRange-select"
              placeholder="Select hour range"
              displayEmpty
              onChange={(ev) =>
                setSearchQueryParams({
                  hourRange: hourRanges[parseInt(ev.target.value.toString())],
                })
              }
              label="Hour Range"
              value={hourRangeIndex}
            >
              <MenuItem value={-1} />
              {hourRanges.map((hourRange, index) => (
                <MenuItem
                  key={hourRange}
                  value={index}
                  selected={index === hourRangeIndex}
                >
                  {hourRange}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel variant="outlined" htmlFor="year-select">
              Year
            </InputLabel>
            <Select
              name="year-select"
              id="year-select"
              placeholder="Select year"
              displayEmpty
              onChange={(ev) =>
                setSearchQueryParams({
                  year: years[parseInt(ev.target.value.toString())],
                })
              }
              label="Year"
              value={yearIndex}
            >
              <MenuItem value={-1} />
              {years.map((year, index) => (
                <MenuItem
                  key={year}
                  value={index}
                  selected={index === yearIndex}
                >
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(ev) =>
                    setSearchQueryParams({
                      linkageFront: ev.target.checked,
                    })
                  }
                  checked={state.linkageFront}
                />
              }
              label="Front Linkage"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(ev) =>
                    setSearchQueryParams({
                      ptoFront: ev.target.checked,
                    })
                  }
                  checked={state.ptoFront}
                />
              }
              label="Front PTO"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(ev) =>
                    setSearchQueryParams({
                      frontLoader: ev.target.checked,
                    })
                  }
                  checked={state.frontLoader}
                />
              }
              label="Front Loader"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </>
  );
};
