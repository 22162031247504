import React, { useMemo } from 'react';

import {
  convertHourRangesToHours,
  convertThousandsToK,
} from 'app/viewAreas/machines/quickcalculator/helpers';
import {
  ContextPrice,
  SearchQueryParams,
} from 'app/viewAreas/machines/quickcalculator/types';
import {
  CartesianGrid,
  Label,
  Tooltip as RechartsTooltip,
  ReferenceDot,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from 'recharts';

import { Grid } from '@mui/material';
import { CustomScatterplotTooltip } from './CustomScatterplotTooltip';

type Props = {
  contextPrices: ContextPrice[];
  state: SearchQueryParams;
  price: number;
  hasContextPrices: boolean;
};

export const PriceHoursGraph = ({
  contextPrices,
  state,
  price,
  hasContextPrices,
}: Props): JSX.Element => {
  const getRoundedMaximumHours = useMemo(() => {
    /**
     * Calculate the maximum of the engine hours to set the
     * range of the x-axis of the scatterplot.
     *
     * TODO: Think about making this function more robust to outliers.
     */
    // Convert the hour range to hours. If the hour range is not available,
    // set hours to 0
    const hours = convertHourRangesToHours(state.hourRange) ?? 0;

    // If hours equals 0 or we do not have the context prices, return 0
    if (hours === 0 || !hasContextPrices) {
      return 0;
    }

    // Calculate the ceiling of the maximum hours to the next 1000
    return (
      Math.ceil(Math.max(...contextPrices.map((p) => p.hours), hours) / 1000) *
      1000
    );
  }, [contextPrices, hasContextPrices, state.hourRange]);

  const getRoundedMaximumPrice = useMemo(() => {
    /**
     * Calculate the maximum of the context prices and the predicted price
     * to set the range of the y-axis of the scatterplot.
     *
     * If either the context prices or the predicted price are not available
     * set the maximum to 0.
     *
     * Calculate the ceiling of the maximum price to the next 10000.
     *
     * TODO: Think about making this function more robust to outliers.
     */
    if (hasContextPrices && price) {
      return (
        Math.ceil(
          Math.max(...contextPrices.map((p) => p.unitPrice), price) / 10000,
        ) * 10000
      );
    }
    return 0;
  }, [contextPrices, hasContextPrices, price]);

  return (
    <>
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <h2 style={{ color: 'green' }}>
          Price Prediction Compared to Price of Similar Tractors Advertised in
          the Last Year
        </h2>
      </Grid>
      <ResponsiveContainer width={'100%'} height={320}>
        <ScatterChart
          height={250}
          margin={{ top: 20, right: 40, bottom: 20, left: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="hours"
            name="Engine Hours"
            domain={[0, getRoundedMaximumHours]}
            unit="h"
            type="number"
            label={{
              value: 'Engine Usage Hours (h)',
              position: 'bottom',
            }}
          />
          <YAxis
            tickFormatter={convertThousandsToK}
            dataKey="unitPrice"
            name="Unit Price"
            unit="€"
            type="number"
            domain={[0, getRoundedMaximumPrice]}
            label={{
              value: 'Advertised price (€)',
              angle: -90,
              position: { x: -17, y: 80 },
            }}
          />
          <YAxis yAxisId="right" orientation="right">
            <Label
              value="Advertised price (€)"
              offset={15}
              position={{ x: 40, y: 80 }}
              angle={-90}
            />
          </YAxis>
          <RechartsTooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<CustomScatterplotTooltip />}
          />
          <Scatter name="Machines" data={contextPrices} fill="#073328" />
          <ReferenceDot
            x={convertHourRangesToHours(state.hourRange)}
            y={price}
            fill="#FF630F"
          />
        </ScatterChart>
      </ResponsiveContainer>
    </>
  );
};
