import { Machine } from 'app/viewAreas/machines/machineselector/Machine';
import { machineSelectorClient } from 'app/viewAreas/machines/machineselector/machineSelectorClient';

type MachineResponse = {
  Brand_Name: string;
  Country: string;
  Exact_Valuation_Num: number;
  Hours: number;
  Machine_Code: string;
  Mapped_Model: string;
  Price_Score_2: number;
  Unit_Price_Num: number;
  Year: number;
  Zoho_Link: string;
  Efarm_Link: string;
  Created_Date: string;
  Days_Online: number;
  Location_Score: number;
  Recency_Score_norm: number;
  Price_Score_norm: number;
  Magic_Score: number;
  Dealer_Name: string;
};

export const getMachines = (): Promise<Machine[]> =>
  machineSelectorClient
    .get<MachineResponse[]>(`/api/v1/beta/machine_selector`)
    .then((res) =>
      res.data.map(
        (m): Machine => ({
          brand: m.Brand_Name,
          country: m.Country,
          createdAt: m.Created_Date,
          daysOnline: m.Days_Online,
          exactValuation: m.Exact_Valuation_Num,
          engineHours: m.Hours,
          zohoLink: m.Zoho_Link,
          locationScore: m.Location_Score,
          machineGroupId: m.Machine_Code,
          magicScore: m.Magic_Score,
          model: m.Mapped_Model,
          priceScore: m.Price_Score_norm,
          priceScore2: m.Price_Score_2,
          recencyScore: m.Recency_Score_norm,
          unitPrice: m.Unit_Price_Num,
          yearOrProduction: m.Year,
          dealerName: m.Dealer_Name,
        }),
      ),
    );
