import brandModels from './brand_models.json';
import countryList from './country_isocode.json';

export const brands = Object.getOwnPropertyNames(brandModels)
  .sort()
  .map((brand) => {
    brandModels[brand].sort();
    return brand;
  });

export const countries = Object.getOwnPropertyNames(countryList).sort();

export const hourRanges = [
  '101-250',
  '251-500',
  '501-750',
  '751-1000',
  '1001-1500',
  '1501-2000',
  '2001-2500',
];

export const years = Array.from(
  { length: 6 },
  (_, i) => new Date().getFullYear() - i,
).sort();
