import { Layout } from 'app/components/Layout/Loadable';
import { ProtectedRoute } from 'app/ProtectedRoute';
import { getViewAreas } from 'app/viewAreas/viewAreasRegistry';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { HomePage } from './pages/HomePage/Loadable';

import '../styles/global.css';

const viewAreas = getViewAreas();

export const App: FC = () => {
  return (
    <BrowserRouter>
      <Helmet htmlAttributes={{ lang: 'en' }} />

      <Switch>
        <ProtectedRoute
          exact
          path="/"
          component={() => (
            <Layout title="E-FARM Admin System">
              <HomePage />
            </Layout>
          )}
        />
        {viewAreas.flatMap((viewArea) =>
          viewArea.children.map((view) => {
            const path = `${viewArea.route}${view.route}`;
            const title = `${viewArea.label} ${view.label}`;
            return (
              <ProtectedRoute
                key={path}
                exact
                path={path}
                component={() => (
                  <Layout title={title}>
                    <view.component />
                  </Layout>
                )}
                requiredRoles={viewArea.requiredRoles}
              />
            );
          }),
        )}

        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};
