import { Check, ContentPaste, Download, Link } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  FormControlLabel,
  Grid,
  NativeSelect,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { environment } from 'environment/environment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { generateReportUrl } from './generateReportUrl';
import { ReportFormat } from './ReportFormat';
import { ReportLanguage } from './ReportLanguage';
import { ReportType } from './ReportType';
import { salesManager } from './salesManager';
import { PDFNoMachineIDFormLazy } from './PDFNoMachineIdForm/Loadable';

const na = '- not specified -';

export const PDFForm = () => {
  const { search } = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(search);
  const [state, setState] = useState<{
    format: ReportFormat;
    language: ReportLanguage;
  }>({
    format: ReportFormat.Basic,
    language: ReportLanguage.en,
  });
  const [link, setLink] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmCopy, setConfirmCopy] = useState(false);

  const machineId = (queryParams.get('machineId') ?? '').toUpperCase();
  const name = queryParams.get('name');
  const email = queryParams.get('email');
  const phone = queryParams.get('phone');
  const profilePicture = salesManager[(email ?? '').toLowerCase()] ?? null;

  useEffect(() => {
    if (!machineId) {
      return;
    }
    setIsLoading(true);
    generateReportUrl(ReportType.HTML, {
      name,
      email,
      phone,
      profilePicture,
      format: state.format,
      language: state.language,
      machineId,
    })
      .then((url) => setLink(url))
      .finally(() => setIsLoading(false));
  }, [name, email, phone, profilePicture, state, machineId]);

  const handeCopyClick = useCallback(() => {
    if (link) {
      copy(link);
      setConfirmCopy(true);
      setTimeout(() => setConfirmCopy(false), 1000);
    }
  }, [link]);

  if (!machineId) {
    return <PDFNoMachineIDFormLazy />;
  }

  const handleDownloadClick = () => {
    generateReportUrl(ReportType.PDF, {
      name,
      email,
      phone,
      profilePicture,
      format: state.format,
      language: state.language,
      machineId,
    }).then((url) => {
      window.location.href = url;
    });
  };

  const goToGeneration = () => {
    location.search = '';
    history.push(location);
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Create PDF for Machine Code:{' '}
            {machineId ? (
              <a
                title="View machine on website"
                target="_blank"
                rel="noreferrer"
                href={`${environment.websiteUrl}/${machineId}`}
              >
                {machineId}
              </a>
            ) : (
              na
            )}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Footer Contact Data</Typography>
        </Grid>

        <Grid container item spacing={1}>
          <Grid item xs={1}>
            <Typography>
              <strong>Name:</strong>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>{name ?? na}</Typography>
          </Grid>

          <Grid item xs={1}>
            <Typography>
              <strong>Email:</strong>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>{email ?? na}</Typography>
          </Grid>

          <Grid item xs={1}>
            <Typography>
              <strong>Phone:</strong>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>{phone ?? na}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography>
              <strong>Picture:</strong>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            {profilePicture ? (
              <img
                src={profilePicture}
                alt=""
                style={{
                  borderRadius: '50%',
                  width: 64,
                  height: 64,
                  border: '1px solid #8cc63f',
                  padding: 1,
                }}
              />
            ) : (
              na
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Options</Typography>
        </Grid>

        <Grid container item spacing={1} alignItems="center">
          <Grid item xs={1}>
            <Typography>
              <strong>Format:</strong>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <RadioGroup
              row
              name="format"
              value={state.format}
              onChange={(ev) =>
                setState((s) => ({
                  ...s,
                  format: ev.target.value as ReportFormat,
                }))
              }
            >
              <FormControlLabel
                value={ReportFormat.Basic}
                control={<Radio />}
                label="Basic"
              />
              <FormControlLabel
                value={ReportFormat.InspectionDekra}
                control={<Radio />}
                label="Inspection - DEKRA"
              />
              <FormControlLabel
                value={ReportFormat.InspectionNonDekra}
                control={<Radio />}
                label="Inspection - non-DEKRA"
              />
              <FormControlLabel
                value={ReportFormat.VinEnhanced}
                control={<Radio />}
                label="VIN Enhanced"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={1}>
            <Typography>
              <strong>Language:</strong>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <NativeSelect
              value={state.language}
              onChange={(ev) =>
                setState((s) => ({
                  ...s,
                  language: ev.target.value as ReportLanguage,
                }))
              }
            >
              <option value={ReportLanguage.en}>English</option>
              <option value={ReportLanguage.de}>German</option>
              <option value={ReportLanguage.fr}>French</option>
              <option value={ReportLanguage.es}>Spanish</option>
              <option value={ReportLanguage.cs}>Czech</option>
              <option value={ReportLanguage.el}>Greek</option>
              <option value={ReportLanguage.it}>Italian</option>
              <option value={ReportLanguage.pl}>Polish</option>
              <option value={ReportLanguage.ro}>Romanian</option>
              <option value={ReportLanguage.ru}>Russian</option>
              <option value={ReportLanguage.hu}>Hungarian</option>
              <option value={ReportLanguage.bg}>Bulgarian</option>
              <option value={ReportLanguage.sl}>Slovenian</option>
              <option value={ReportLanguage.da}>Danish</option>
              <option value={ReportLanguage.sr}>Serbian</option>
            </NativeSelect>
          </Grid>
        </Grid>

        <Grid container item spacing={1}>
          <Grid item xs={12}>
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              onClick={handleDownloadClick}
              startIcon={<Download />}
            >
              Download PDF
            </Button>

            <LoadingButton
              sx={{ mr: 2 }}
              variant="outlined"
              color="primary"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<Link />}
              href={link ?? '#'}
              target="_blank"
              disabled={!link}
            >
              Open Link
            </LoadingButton>

            <LoadingButton
              variant="outlined"
              color="primary"
              loading={isLoading}
              loadingPosition="start"
              startIcon={confirmCopy ? <Check /> : <ContentPaste />}
              onClick={handeCopyClick}
              disabled={!link}
            >
              Copy Link
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="text"
            onClick={goToGeneration}
            sx={{ paddingInlineStart: 0 }}
          >
            Create PDF for different machine
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
