import * as MMD from '@it-efarm/model-metadata';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { isEmpty } from 'utils/object';

import {
  Brand,
  deriveExpansionState,
  ExpansionState,
  ID,
  initialState,
  MachineModel,
} from './domain';

// =============================================================================
// Simple
// =============================================================================

export const selectSlice = (state: RootState) =>
  state.machineModels || initialState;

export const selectAllBrands = createSelector(
  [selectSlice],
  (s) => s.allBrands,
);

export const selectQuery = createSelector([selectSlice], (s) => s.query);
export const selectDiffById = createSelector([selectSlice], (s) => s.diffById);
export const selectIdsByBrandRaw = createSelector(
  [selectSlice],
  (s) => s.idsByBrand,
);

const selectFilteredIdsByBrand = createSelector(
  [selectSlice],
  (s) => s.filteredIdsByBrand,
);

export const selectMachineModelById = createSelector(
  [selectSlice],
  (s) => s.machineModelById,
);

const selectExpandedBrandsRaw = createSelector(
  [selectSlice],
  (s) => s.expandedBrands,
);

export const selectIsFetching = createSelector(
  [selectSlice],
  (s) => s.isFetching,
);

export const selectFilteredBrandsRaw = createSelector(
  [selectSlice],
  (s) => s.filteredBrands,
);

const selectFailureById = createSelector([selectSlice], (s) => s.failureById);

const selectVisibleFailureIds = createSelector(
  [selectSlice],
  (s) => s.visibleFailuresIds,
);

// =============================================================================
// Derived
// =============================================================================

export const selectVisibleFailures = createSelector(
  [selectFailureById, selectVisibleFailureIds],
  (byId, ids) => ids.map((id) => byId[id]),
);

export const selectHasAnyData = createSelector(
  [selectMachineModelById],
  (m) => !isEmpty(m),
);

export const selectHasAnyChanges: (s: RootState) => boolean = createSelector(
  [selectDiffById],
  (diffById) => !isEmpty(diffById),
);

export const selectMachineModelsByBrand = createSelector<
  [
    typeof selectMachineModelById,
    typeof selectIdsByBrandRaw,
    typeof selectAllBrands,
  ],
  Record<Brand, MachineModel[]>
>(
  [selectMachineModelById, selectIdsByBrandRaw, selectAllBrands],
  (byId, idsByBrand, brands) => {
    return brands.reduce((m, brand) => {
      m[brand] = idsByBrand[brand].map((id) => byId[id]);
      return m;
    }, {});
  },
);

export const selectMachineModel = createSelector<
  [typeof selectMachineModelById, (_: RootState, id: ID) => ID],
  MachineModel
>([selectMachineModelById, (_, id) => id], (byId, id) => byId[id]);

export const selectDiff = createSelector<
  [typeof selectDiffById, (_: RootState, id: ID) => ID],
  MMD.api.MachineModelDataDto
>([selectDiffById, (_, id) => id], (byId, id) => byId[id]);

export const selectExpandedBrandsSummary: (s: RootState) => ExpansionState =
  createSelector([selectExpandedBrandsRaw], deriveExpansionState);

export const selectFilteredBrands: (s: RootState) => Brand[] = createSelector(
  [selectFilteredBrandsRaw, selectAllBrands],
  (filteredBrands, allBrands) =>
    filteredBrands.length === 0 ? allBrands : filteredBrands,
);

/**
 * When machine models are being filtered, returns filtered IDs, otherwise the entire set.
 */
export const selectIdsByBrand = createSelector<
  [
    typeof selectQuery,
    typeof selectFilteredIdsByBrand,
    typeof selectIdsByBrandRaw,
    (_, brand: Brand) => Brand,
  ],
  ID[]
>(
  [
    selectQuery,
    selectFilteredIdsByBrand,
    selectIdsByBrandRaw,
    (_, brand) => brand,
  ],
  (query, filteredIdsByBrand, allIdsByBrand, brand) => {
    const idsByBrand =
      isEmpty(filteredIdsByBrand) && query === undefined
        ? allIdsByBrand
        : filteredIdsByBrand;

    return idsByBrand[brand] || [];
  },
);

export const selectIsBrandExpanded = createSelector<
  [typeof selectExpandedBrandsRaw, (_, brand: Brand) => Brand],
  boolean
>(
  [selectExpandedBrandsRaw, (_, brand: Brand) => brand],
  (s, brand) => !!s[brand],
);
