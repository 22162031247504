import { Typography } from '@mui/material';
import { SearchQueryParams } from 'app/viewAreas/machines/quickcalculator/types';
import React from 'react';
import { formatPrice } from 'utils/format/formatPrice';
import trustLevelColor from '../constants/trust_level_color.json';

type Props = {
  searchQueryParams: SearchQueryParams;
  loading: boolean;
  trustLevel: string | null;
  price: number | null;
  updating: boolean;
  isStateInitialState: boolean;
};

export const PriceTrustLevel = ({
  searchQueryParams,
  loading,
  trustLevel,
  price,
  updating,
  isStateInitialState,
}: Props): JSX.Element => {
  return (
    <Typography variant="h4" gutterBottom>
      {searchQueryParams.brand || ' '} {searchQueryParams.model || ' '}
      <Typography
        color={loading ? '#eeeeee' : `${trustLevelColor[`${trustLevel}`]}`}
        component="span"
        fontSize="inherit"
      >
        <strong>
          {price && !updating && !isStateInitialState
            ? ` -  ${formatPrice(price)} (${
                trustLevel ? trustLevelColor[`${trustLevel}_meaning`] : ''
              })`
            : ''}
        </strong>
      </Typography>
    </Typography>
  );
};
