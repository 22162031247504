import createDeepMerge from '@fastify/deepmerge';

export const mergeDeep = createDeepMerge();

export function isEmpty(m: object): boolean {
  for (const property in m) {
    if (m.hasOwnProperty(property)) return false;
  }

  return true;
}
