import { useAuth0, User } from '@auth0/auth0-react';

const efarmAdminRole = 'EFarmAdmin';
const getUserRoles = (user: User | undefined): string[] =>
  user?.['https://app.e-farm.com/roles'] ?? [];

const hasRoles = (user: User | undefined, roles: string[] = []): boolean => {
  const userRoles = getUserRoles(user);
  return [efarmAdminRole, ...roles].every((role) => userRoles.includes(role));
};

const getMissingRoles = (
  user: User | undefined,
  roles: string[] = [],
): string[] => {
  const userRoles = getUserRoles(user);
  return [efarmAdminRole, ...roles].filter((role) => !userRoles.includes(role));
};

export const useRoles = () => {
  const { user } = useAuth0();
  return {
    hasRoles: (roles?: string[]) => hasRoles(user, roles),
    getMissingRoles: (roles?: string[]) => getMissingRoles(user, roles),
  };
};
