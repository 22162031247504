import {
  StyledEngineProvider,
  Theme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import React from 'react';
import { theme } from './theme';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const ThemeProvider: React.FC = (props) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
  </StyledEngineProvider>
);
