import { Typography } from '@mui/material';
import React from 'react';

import { useTokenInterceptor } from 'app/hooks';
import { client } from './model-metadata.client';
import { environment } from 'environment/environment';
import { DefaultProps } from 'utils/react';
import { Failures } from './Failures';
import { FilterBrands } from './FilterBrands';
import { Infos } from './Infos';
import { Table } from './MachineModelsTables';
import { QueryModel } from './QueryModel';
import { ResetAllChanges } from './ResetAllChanges';
import {
  FilterContext,
  useFilterState,
  useInitialize,
  useSlice,
} from './state';

export const ListModels: React.FC<DefaultProps> = () => {
  useTokenInterceptor(client, environment.modelMetadata.auth0Audience);
  useSlice();
  useInitialize();
  const value = useFilterState();

  return (
    <main className="pb-4">
      <Typography variant="h6" className="mb-4">
        Machine models grouped by brands.
      </Typography>
      <Infos />
      <Failures />
      <header className="mt-4 flex items-center">
        <FilterBrands />
        <QueryModel />
        <ResetAllChanges />
      </header>
      <FilterContext.Provider value={value}>
        <Table />
      </FilterContext.Provider>
    </main>
  );
};
