// [IMPORT NEW VIEW ABOVE] < Needed for generating views seamlessly
import { LocalOffer } from '@mui/icons-material';
import { Reporting } from 'app/viewAreas/smarttrade/reporting/Loadable';
import { TViewArea } from 'app/viewAreas/TViewArea';
import { Country } from './country/Loadable';
import { Dealer } from './dealer/Loadable';
import { DealerFamily } from './dealerfamily/Loadable';
import { FeaturePage } from './feature/Loadable';
import { User } from './user/Loadable';
import { MachineImport } from 'app/viewAreas/smarttrade/machine-import/Loadable';

export const viewArea: TViewArea = {
  route: '/smarttrade',
  label: 'SmartTrade',
  icon: LocalOffer,
  requiredRoles: ['SmartTradeAdmin'],
  children: [
    {
      route: '/country',
      label: 'Country',
      component: Country,
    },
    {
      route: '/dealerfamily',
      label: 'DealerFamily',
      component: DealerFamily,
    },
    {
      route: '/dealer',
      label: 'Dealer',
      component: Dealer,
    },
    {
      route: '/user',
      label: 'User',
      component: User,
    },
    {
      route: '/feature',
      label: 'Feature',
      component: FeaturePage,
    },
    {
      route: '/reporting',
      label: 'Reporting',
      component: Reporting,
    },
    {
      route: '/machine-import',
      label: 'Machine Import',
      component: MachineImport,
    },
    // [INSERT NEW VIEW BELOW] < Needed for generating views seamlessly
  ],
};
