export const environment = {
  nodeEnv: process.env.NODE_ENV,
  ada: {
    backendUrl: process.env.REACT_APP_ADA_URL || 'https://ada-test.e-farm.com',
    auth0Audience: process.env.REACT_APP_ADA_AUTH0_AUDIENCE || '',
  },
  sentryDNS: process.env.REACT_APP_SENTRY_DNS,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  },
  smartTrade: {
    backendUrl: process.env.REACT_APP_SMARTTRADE_BACKEND_URL || '',
    auth0Audience: process.env.REACT_APP_SMARTTRADE_AUTH0_AUDIENCE || '',
  },
  websiteUrl:
    process.env.REACT_APP_WEBSITE_URL || 'https://www-test.e-farm.com',

  machines: {
    pdfServiceUrl:
      process.env.REACT_APP_PDF_SERVICE_URL || 'https://pdf-test.e-farm.com',
    // PICO
    picoServiceUrl:
      process.env.REACT_APP_PICO_SERVICE_URL ||
      'https://pico-v3-dev-skkzlx7oiq-ew.a.run.app',
    picoAudience:
      process.env.REACT_APP_PICO_AUTH0_AUDIENCE ||
      'https://ds-test.e-farm.com/api',

    // MACHINE SELECTOR
    machineSelectorUrl:
      process.env.REACT_APP_MACHINE_SELECTOR_URL ||
      'https://ds-machines-api-test-skkzlx7oiq-ew.a.run.app',
    machineSelectorAudience:
      process.env.REACT_APP_MACHINE_SELECTOR_AUTH0_AUDIENCE ||
      'https://ds-test.e-farm.com/api',
  },
  modelMetadata: {
    serviceUrl:
      process.env.REACT_APP_MODEL_METADATA_SERVICE_URL ||
      'https://model-metadata-test.e-farm.com',
    auth0Audience: process.env.REACT_APP_MMD_AUTH0_AUDIENCE || 'model-metadata',
  },
  machineSearch: {
    baseUrl:
      process.env.REACT_APP_MACHINE_SEARCH_URL ||
      'https://machine-search-testing.e-farm.com',
    auth0Audience:
      process.env.REACT_APP_MACHINE_SEARCH_AUDIENCE ||
      'https://haystack-test.e-farm.com/api',
  },
  qms: {
    cacheProxyUrl: process.env.REACT_APP_QMS_CACHE_PROXY_URL || '',
  },
};
