import ClearIcon from '@mui/icons-material/Clear';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultProps } from 'utils/react';
import { Brand } from './domain';

import {
  selectAllBrands,
  selectFilteredBrandsRaw,
  selectHasAnyData,
} from './selectors';
import { actions } from './state';

const renderOptionsValue = (value: string[]) =>
  value.length > 1 ? `(${value.length}) ${value.join(', ')}` : value.join(', ');

export const FilterBrands: React.FC<DefaultProps> = () => {
  const filteredBrands = useSelector(selectFilteredBrandsRaw);
  const allBrands = useSelector(selectAllBrands);
  const dispatch = useDispatch();
  const filterBrands = React.useCallback(
    ({ target: { value } }: SelectChangeEvent<Brand[]>) => {
      const brands = (
        typeof value === 'string' ? value.split(',') : value
      ) as Brand[];
      dispatch(actions.filterBrands(brands));
    },
    [dispatch],
  );
  const clearFilteredBrands = React.useCallback(
    () => dispatch(actions.clearFilteredBrands()),
    [dispatch],
  );
  const hasAnyData = useSelector(selectHasAnyData);

  return (
    <FormControl
      className={`w-56 ${filteredBrands.length ? 'bg-yellow-200' : ''}`}
      size="small"
      variant="outlined"
      disabled={!hasAnyData}
    >
      <InputLabel id="filter-brands-label">Filter Brands</InputLabel>
      <Select
        labelId="filter-brands-label"
        id="filter-brands"
        multiple
        value={filteredBrands}
        native={false}
        renderValue={renderOptionsValue}
        onChange={filterBrands}
        input={
          <OutlinedInput
            className="rounded-none"
            endAdornment={
              filteredBrands.length > 0 ? (
                <InputAdornment position="start">
                  <IconButton onClick={clearFilteredBrands} edge="start">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }
            label="Filter Brands"
          />
        }
      >
        {allBrands.map((brand) => (
          <MenuItem key={`select-brand-${brand}`} value={brand}>
            {brand}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
