import { Alert, IconButton, Link, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import { selectVisibleFailures } from './selectors';
import { actions } from './state';
import { DefaultProps } from 'utils/react';
import { Failure } from './domain';

const FailureAlert: React.FC<DefaultProps<Failure>> = (failure) => {
  const dispatch = useDispatch();
  const hideFailure = useCallback(
    () => dispatch(actions.hideFailure(failure.id)),
    [failure, dispatch],
  );

  return (
    <Alert
      key={`failure-${failure.id}`}
      className="mt-2 text-gray-500"
      severity="error"
      action={
        <IconButton onClick={hideFailure}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Typography>{failure.message}</Typography>
      <Typography className="mt-2">
        If this issue persists, please contact developers in{' '}
        <Link href="https://e-farmgmbh.slack.com/archives/C01MV62L093">
          #it-crew
        </Link>
      </Typography>
    </Alert>
  );
};

export const Failures: React.FC<DefaultProps> = () => {
  const failures = useSelector(selectVisibleFailures);

  return (
    <>
      {failures.map((failure) => (
        <FailureAlert key={`failure-${failure.id}`} {...failure} />
      ))}
    </>
  );
};
