import React, { FC, useCallback, useEffect, useState } from 'react';

import { Alert, CircularProgress, Grid, Link } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { useTokenInterceptor } from 'app/hooks';
import { gridDefinition } from 'app/viewAreas/machines/machineselector/gridDefinition';
import { machineSelectorClient } from 'app/viewAreas/machines/machineselector/machineSelectorClient';
import { environment } from 'environment/environment';
import { Machine } from './Machine';
import { getMachines } from './api';

export const MachineSelector: FC = React.memo(() => {
  useTokenInterceptor(
    machineSelectorClient,
    environment.machines.machineSelectorAudience,
  );
  const [machines, setMachines] = useState<Machine[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchMachines = useCallback(() => {
    setLoading(true);
    getMachines()
      .then(setMachines)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => fetchMachines(), [fetchMachines]);
  const getRowId = useCallback((m: Machine) => m.machineGroupId, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {
          <Alert severity="info">
            Please send any feedback to the{' '}
            <Link href="https://e-farmgmbh.slack.com/archives/C01NZ07BLCV">
              #ds-team
            </Link>{' '}
            channel in Slack. Machine scoring is described here{' '}
            <Link href="https://efarm.atlassian.net/wiki/spaces/D/pages/2415853569/Machine+Scoring">
              here
            </Link>
          </Alert>
        }
      </Grid>

      <Grid item xs={12}>
        <div style={{ height: loading ? 100 : 650 }}>
          <DataGrid
            getRowId={getRowId}
            rows={machines}
            columns={gridDefinition}
            pageSize={50}
            rowsPerPageOptions={[50]}
          />
        </div>
      </Grid>

      {loading && (
        <Grid item xs={12} justifyContent="center" display="flex">
          <CircularProgress color="primary" />
        </Grid>
      )}
    </Grid>
  );
});
