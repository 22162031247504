import { v4 as uuid } from 'uuid';
import * as flex from 'flexsearch';
import * as MMD from '@it-efarm/model-metadata';

export type State = {
  // data
  machineModelById: Record<ID, MachineModel>;
  diffById: Record<ID, MMD.api.MachineModelDataDto>;
  idsByBrand: Record<Brand, ID[]>;
  allBrands: Brand[];
  // ui
  query?: string;
  filteredIdsByBrand: Record<Brand, ID[]>;
  expandedBrands: Record<Brand, boolean>;
  filteredBrands: Brand[];
  isFetching: boolean;
  // failures
  failureById: Record<FailureID, Failure>;
  visibleFailuresIds: FailureID[];
};

export const initialState: State = {
  machineModelById: {},
  diffById: {},
  idsByBrand: {},
  allBrands: [],
  query: undefined,
  filteredIdsByBrand: {},
  expandedBrands: {},
  filteredBrands: [],
  isFetching: false,
  failureById: {},
  visibleFailuresIds: [],
};

export type FilterState = {
  setFirstPage: () => void;
  setPrevPage: () => void;
  setNextPage: () => void;
  setLastPage: () => void;

  filteredMachineModelByBrand: Map<Brand, MachineModel[]>;
  paginatedBrands: Brand[];
  currentPage: number;
  maxPage: number;
  first: number;
  last: number;
  total: number;
  isFirstPage: boolean;
  isLastPage: boolean;
};

export const createInitialFilterState = () => ({
  setFirstPage: () => undefined,
  setPrevPage: () => undefined,
  setNextPage: () => undefined,
  setLastPage: () => undefined,

  filteredMachineModelByBrand: new Map(),
  paginatedBrands: [],
  currentPage: 1,
  maxPage: 1,
  first: 0,
  last: 0,
  total: 0,
  isFirstPage: true,
  isLastPage: true,
});

export type MachineModel = Omit<MMD.api.MachineModelDto, 'brand'> & {
  id: ID;
  brand: Brand;
};
export const MachineModel = {
  fromDto: (dto: MMD.api.MachineModelDto): MachineModel => ({
    ...dto,
    brand: Brand.fromString(dto.brand),
    // We need a unique ID for flexsearch.
    id: ID.fromDto(dto),
  }),
  toDto: ({
    id: _id,
    ...machineModel
  }: MachineModel): MMD.api.MachineModelDto => machineModel,
};

export type ID = string & { __tag: 'MACHINE_MODEL_ID' };
export const ID = {
  fromDto: (m: MMD.api.MachineModelDto): ID =>
    `${m.brand}-${m.model}-${m.yearOfProductionStart}-${m.yearOfProductionEnd}-${m.factoryCountryCode}` as ID,
};

export type Index = flex.Document<MachineModel>;
export type Brand = MMD.api.MachineModelDto['brand'] & {
  __tag: 'MACHINE_MODEL_BRAND';
};
export const Brand = {
  fromString: (brand: string): Brand => brand as Brand,
};
export type QueryResult = MachineModel[];

export type ExpansionState = 'some' | 'all' | 'none';
export const ExpansionState = {
  SOME: 'some',
  ALL: 'all',
  NONE: 'none',
} as const;

export const deriveExpansionState = (
  m: Record<Brand, boolean>,
): ExpansionState =>
  Object.values(m).reduce<ExpansionState>((visibility, isVisible) => {
    switch (visibility) {
      case ExpansionState.SOME: {
        return visibility;
      }
      case ExpansionState.NONE: {
        return isVisible ? ExpansionState.ALL : visibility;
      }
      case ExpansionState.ALL: {
        return isVisible ? visibility : ExpansionState.SOME;
      }
    }
  }, ExpansionState.NONE);

export const recordFromConst = <T, K extends string>(
  keys: K[],
  value: T,
): Record<K, T> =>
  keys.reduce((m, key) => {
    m[key] = value;
    return m;
  }, {} as Record<K, T>);

export type Failure = {
  id: FailureID;
  message: string;
};

export const Failure = {
  create: ({ message }: Omit<Failure, 'id'>): Failure => ({
    id: uuid() as FailureID,
    message,
  }),
};

export type FailureID = string & { __tag: 'FAILURE_ID' };
