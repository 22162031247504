import { AgricultureOutlined } from '@mui/icons-material';
import { TViewArea } from 'app/viewAreas/TViewArea';
import { ListModels } from './list-models/ListModels';
import { DebugParseModel } from './debug-parse-model/DebugParseModel';

export const viewArea: TViewArea = {
  route: '/machine-models',
  label: 'Machine Models',
  icon: AgricultureOutlined,
  children: [
    {
      route: '/list-models',
      label: 'List Models',
      component: ListModels,
    },
    {
      route: '/debug-parse-model',
      label: 'Debug Parse Model',
      component: DebugParseModel,
    },
    // [INSERT NEW VIEW BELOW] < Needed for generating views seamlessly
  ],
};
