import { AverageContextPrice } from 'app/viewAreas/machines/quickcalculator/types';
import React from 'react';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

export const CustomAreaChartTooltip = ({
  payload,
  active,
}: TooltipProps<ValueType, NameType>): JSX.Element | null => {
  if (active && payload?.length) {
    const { averagePrice, count, predictedPrice, maxPrice } =
      (payload[0]?.payload as AverageContextPrice) ?? {};

    return (
      <div
        style={{
          backgroundColor: 'rgba(97, 214, 58)',
          padding: '10px',
          opacity: 0.8,
        }}
      >
        <>
          <p>Average Price: {averagePrice}</p>
          <p>Count: {count}</p>
          <p>Predicted Price: {predictedPrice}</p>
          <p>Max Price: {maxPrice}</p>
        </>
      </div>
    );
  } else {
    return null;
  }
};
