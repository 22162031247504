import { CustomAreaChartTooltip } from 'app/viewAreas/machines/quickcalculator/Components/HistoricalPriceGraph/CustomAreaChartTooltip';
import {
  calculateWithAvgPrices,
  convertThousandsToK,
} from 'app/viewAreas/machines/quickcalculator/helpers';
import { ContextPrice } from 'app/viewAreas/machines/quickcalculator/types';
import React, { useMemo } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

type Props = {
  contextPrices: ContextPrice[];
  predictedPrice: number | null;
};

export const HistoricalPriceGraph = ({
  contextPrices,
  predictedPrice,
}: Props): JSX.Element => {
  const avgPriceHistory = useMemo(() => {
    return calculateWithAvgPrices(contextPrices).map((p) => ({
      ...p,
      predictedPrice,
    }));
  }, [contextPrices, predictedPrice]);

  const ifShouldRender = useMemo(() => {
    const hasCreatedTime = contextPrices.every((p) => Boolean(p.createdTime));
    const contextPricesIsNotEmpty = contextPrices.length >= 1;

    return hasCreatedTime && contextPricesIsNotEmpty;
  }, [contextPrices]);

  if (!ifShouldRender) {
    return <></>;
  }

  return (
    <ResponsiveContainer width={'100%'} height={320}>
      <AreaChart
        data={avgPriceHistory}
        height={450}
        margin={{ top: 20, right: 40, bottom: 20, left: 40 }}
      >
        <XAxis dataKey="yearMonth">
          <Label
            value="Year/Month Ad First Posted"
            offset={-10}
            position="insideBottom"
          />
        </XAxis>
        <YAxis yAxisId="left" unit="€" tickFormatter={convertThousandsToK}>
          <Label
            value="Average Price"
            offset={15}
            angle={-90}
            position={{ x: -10, y: 80 }}
          />
        </YAxis>
        <YAxis yAxisId="right" orientation="right">
          <Label
            value="Count"
            offset={15}
            position={{ x: 40, y: 80 }}
            angle={-90}
          />
        </YAxis>
        <CartesianGrid strokeDasharray="3 3" />
        <Legend
          verticalAlign="top"
          payload={[
            {
              value: 'Count',
              type: 'line',
              color: '#073328',
            },
            { value: 'Average Price', type: 'line', color: '#61D63A' },
            { value: 'Predicted Price', type: 'line', color: '#FF630F' },
          ]}
        />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          content={<CustomAreaChartTooltip />}
        />
        <Area
          type="monotone"
          dataKey="count"
          stroke="#073328"
          fillOpacity={0.4}
          fill="#073328"
          yAxisId="right"
        />
        <Area
          type="monotone"
          dataKey="averagePrice"
          stroke="#61D63A"
          fillOpacity={0.2}
          fill="#61D63A"
          yAxisId="left"
        />
        <Area
          type="monotone"
          dataKey="predictedPrice"
          stroke="#FF630F"
          fillOpacity={0}
          yAxisId="left"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
