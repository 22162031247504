import ArticleIcon from '@mui/icons-material/Article';
import { DisplayDefinitionsCache } from 'app/viewAreas/qms/displaydefinitionscache/Loadable';
import { QuestionnairesCache } from 'app/viewAreas/qms/questionnairescache/Loadable';
import { TViewArea } from 'app/viewAreas/TViewArea';

export const viewArea: TViewArea = {
  route: '/qms',
  label: 'Qms',
  icon: ArticleIcon,
  children: [
    {
      route: '/questionnairescache',
      label: 'Questionnaires Cache',
      component: QuestionnairesCache,
    },
    {
      route: '/displaydefinitionscache',
      label: 'DisplayDefinitions Cache',
      component: DisplayDefinitionsCache,
    },
    // [INSERT NEW VIEW BELOW] < Needed for generating views seamlessly
  ],
};
