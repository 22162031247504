import MMD from '@it-efarm/model-metadata';
import axios from 'axios';
import { environment } from 'environment/environment';

const mmdClientConfig = {
  baseURL: environment.modelMetadata.serviceUrl,
  timeout: 10000,
};

export const client = axios.create(mmdClientConfig);

export const getMachineModels = () =>
  client.get<MMD.api.MachineModelDto[]>(`/machine-models`);

export const postMachineModel = (dto: MMD.api.MachineModelDto) =>
  client.post<MMD.api.MachineModelDto[]>(`/snapshots`, dto);
