import { DateTime } from 'luxon';
import { AverageContextPrice, ContextPrice } from './types';

export const convertHourRangesToHours = (hourRange?: string) => {
  /*
   Convert the hour ranges to hours

  Args:
      hourRange (str): The hour range

  Returns:
      hours (int): The average of the hour range
  */
  switch (true) {
    case hourRange === '101-250':
      return 175;
    case hourRange === '251-500':
      return 375;
    case hourRange === '501-750':
      return 625;
    case hourRange === '751-1000':
      return 875;
    case hourRange === '1001-1500':
      return 1250;
    case hourRange === '1501-2000':
      return 1750;
    case hourRange === '2001-2500':
      return 2250;
    default:
      return undefined;
  }
};

export const queryParamsToString = (
  queryParams: Record<
    string,
    string | number | undefined | null | Array<string | number> | boolean
  >,
) => {
  const urlSearchParams = new URLSearchParams();

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (Array.isArray(value)) {
      value.forEach((v) => {
        if (value !== undefined) {
          urlSearchParams.append(key, `${v}`);
        }
      });
    } else if (value !== undefined) {
      urlSearchParams.append(key, `${value}`);
    }
  });

  return urlSearchParams.toString();
};

type AccumulatorType = {
  [key: string]: AverageContextPrice;
};

export const calculateWithAvgPrices = (
  contextPrices: ContextPrice[] = [],
): AverageContextPrice[] => {
  const yearMonthMap = contextPrices.reduce(
    (accumulator: AccumulatorType, p: ContextPrice) => {
      const yearMonthDate = DateTime.fromISO(p.createdTime);
      const yearMonthDateFormatted = yearMonthDate.toFormat('MM/y');
      const existingYearMonthEntry = accumulator[yearMonthDateFormatted];

      if (existingYearMonthEntry) {
        existingYearMonthEntry.count++;
        existingYearMonthEntry.totalSum += p.unitPrice;
        existingYearMonthEntry.averagePrice =
          existingYearMonthEntry.totalSum / existingYearMonthEntry.count;
        if (existingYearMonthEntry?.maxPrice || 0 < p.unitPrice) {
          existingYearMonthEntry.maxPrice = p.unitPrice;
        }
      } else {
        accumulator[yearMonthDateFormatted] = {
          count: 1,
          yearMonth: yearMonthDateFormatted,
          totalSum: p.unitPrice,
          averagePrice: p.unitPrice,
          isoDate: yearMonthDate.toISO({ includeOffset: false }),
          maxPrice: p.unitPrice,
        };
      }
      return accumulator;
    },
    {},
  );

  return Object.values<AverageContextPrice>(yearMonthMap)
    .sort(
      (yA, yB) =>
        new Date(yA.isoDate).getTime() - new Date(yB.isoDate).getTime(),
    )
    .map((p) => ({
      ...p,
      averagePrice: Number.parseInt(String(p.averagePrice)),
    }));
};

export const convertThousandsToK = (value: number | string): string => {
  const str = String(value);

  if (str === '0') {
    return '0K';
  }

  return str.slice(0, str.length - 3) + 'K';
};
