import {
  configureStore,
  StoreEnhancer,
  SerializableStateInvariantMiddlewareOptions,
} from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { createReducer } from './reducers';
import { mergeDeep } from 'utils/object';
import * as MachineModels from '../app/viewAreas/machine-models/list-models/configure-store';

type MiddlewareOptions = {
  serializableCheck?: boolean | SerializableStateInvariantMiddlewareOptions;
};

const mergeMiddlewareOptions = (xs: MiddlewareOptions[]): MiddlewareOptions =>
  xs.reduce(mergeDeep, {});

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  const enhancers: StoreEnhancer[] = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(
        mergeMiddlewareOptions([MachineModels.defaultMiddlewareOptions]),
      ).concat(sagaMiddleware),
    devTools: {
      // See https://github.com/react-boilerplate/redux-injectors#redux-devtools
      shouldHotReload: false,
    },
    enhancers,
  });

  return store;
}
