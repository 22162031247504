import { Alert } from '@mui/material';
import React from 'react';

export const TrustLevelInfoBox = (): JSX.Element => {
  return (
    <>
      <Alert severity="info">
        Trust levels ranges{' '}
        <q>
          <span style={{ color: 'rgb(0,0,255)' }}>High</span>
        </q>{' '}
        over{' '}
        <q>
          <span style={{ color: 'rgb(102, 153, 204)' }}>Reasonable</span>
        </q>{' '}
        and{' '}
        <q>
          <span style={{ color: 'rgb(140, 140, 140)' }}>Medium</span>
        </q>{' '}
        to{' '}
        <q>
          <span style={{ color: 'rgb(80, 80, 80)' }}>Low</span>
        </q>
        . They denote the level of trust we have in the accuracy of the price
        prediction and are based on maximally to expected APE in combination of
        minimal share of machines in each bucket.
      </Alert>
    </>
  );
};
